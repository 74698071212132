import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import AccountDetailsForm from 'view/public/CreateAccount//AccountDetails/AccountDetailsForm';

export class AccountDetails extends Component {

  render() {
    return (
      <SmartFormProvider>
        <AccountDetailsForm />
      </SmartFormProvider>
    );
  }

}

export default AccountDetails;