import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import NameInput from 'utils/SmartForm/components/NameInput';
import PhoneInput from 'utils/SmartForm/components/PhoneInput';
import TextInput from 'utils/SmartForm/components/TextInput';
import CityInput from 'utils/SmartForm/components/CityInput';
import StateInput from 'utils/SmartForm/components/StateInput';
import PostalCodeInput from 'utils/SmartForm/components/PostalCodeInput';

import styles from 'view/private/body/resident/AccountInfoEdit/AccountInfoEditForm/index.module.css';

export class AccountInfoEditForm extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      AccountInfoEditForm: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_update: PropTypes.string,
        lbl_first_name: PropTypes.string,
        lbl_last_name: PropTypes.string,
        lbl_phone: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_address: PropTypes.string,
        lbl_city: PropTypes.string,
        lbl_state: PropTypes.string,
        lbl_zip: PropTypes.string,
      }),
    }).isRequired,
  };

  static contextType = SmartFormContext;
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.firstName}>
          <NameInput
            inputName='firstName'
            isRequired={true}
            labelText={text.AccountInfoEditForm.lbl_first_name}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.lastName}>
          <NameInput
            inputName='lastName'
            isRequired={true}
            labelText={text.AccountInfoEditForm.lbl_last_name}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.mobile}>
          <PhoneInput
            inputName='phone'
            isRequired={true}
            labelText={text.AccountInfoEditForm.lbl_phone}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.email}>
          <div style={{ fontWeight: 'bold' }}>Email:</div>
          <div style={{ textIndent: '5px' }}>john@doe.com</div>
        </div>
        <div className={styles.address1}>
          <TextInput
            inputName='address'
            isRequired={true}
            labelText={text.AccountInfoEditForm.lbl_address}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.city}>
          <CityInput
            inputName='city'
            isRequired={true}
            labelText={text.AccountInfoEditForm.lbl_city}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.stateZip}>
          <div>
            <StateInput
              inputName='state'
              isRequired={true}
              labelText={text.AccountInfoEditForm.lbl_state}
              style={{ width: '150px', height: '75px' }}
            />
          </div>
          <div style={{ textIndent: '10px' }}>
            <PostalCodeInput
              inputName='zip'
              isRequired={true}
              labelText={text.AccountInfoEditForm.lbl_zip}
              style={{ width: '150px', height: '75px' }}
            />
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            variant='contained'
            onClick={() => this.props.history.goBack()}
          >
            {text.AccountInfoEditForm.btn_cancel}
          </Button>&nbsp;
          <Button
            variant='contained'
            onClick={e => this.updateAccount(e)}
          >
            {text.AccountInfoEditForm.btn_update}
          </Button>
        </div>
      </div>
    );
  }

  updateAccount(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedList = this.context.prepInputList(validObj.inputList);
      alert(JSON.stringify(formattedList));
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }
}

export default withRouter(TranslationHandler(AccountInfoEditForm));