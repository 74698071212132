import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import OneTimePasswordForm from 'view/public/CreateAccount/OneTimePassword/OneTimePasswordForm';

export class SearchProperty extends Component {

  render() {
    return (
      <SmartFormProvider>
        <OneTimePasswordForm />
      </SmartFormProvider>
    );
  }

}

export default SearchProperty;