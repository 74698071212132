import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TranslationHandler from 'utils/translations/translation_handler';

import { 
  Button,
  Menu,
  MenuItem,
  Fade,
} from '@mui/material';

import { changeLanguage } from 'view/actions';
import { LANGUAGES } from 'view/constants';

import styles from 'view/public/Header/index.module.css';
import { coreColors } from 'utils/theme/parking_tree';

const select = state => ({
  language: state.session.language,
});

export class Header extends Component {

  static propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    location: PropTypes.object,
    text: PropTypes.shape({
      PublicHeader: PropTypes.shape({
        btn_help: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    anchorEl: null,
    lang: 'ENGLISH',
  };

  render() {
    const { anchorEl, lang } = this.state;
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.language}>
          <Button 
            onClick={e => this.setState({ anchorEl: e.currentTarget })}
            style={{ color: coreColors.primary }}
          >
            {lang}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => this.setState({ anchorEl: null })}
            TransitionComponent={Fade}
            style={{ top: '25px' }}
          >
            <MenuItem 
              onClick={() => {
                this.setState(
                  { anchorEl: null, lang: 'ENGLISH' },
                  () => this.props.changeLanguage(LANGUAGES.EN)
                );
              }}
            >ENGLISH</MenuItem>
            <MenuItem onClick={() => {
              this.setState(
                { anchorEl: null, lang: 'ESPAÑOL' },
                () => this.props.changeLanguage(LANGUAGES.ES)
              );
            }}
            // sx={{ fontSize: '13px', minHeight: '30' }}
            >ESPAÑOL</MenuItem>
          </Menu>
        </div>
        <div className={styles.help}>
          {!this.props.location.pathname.includes('contact-info') &&
          <Button
            variant='outlined'
            onClick={() => this.props.history.push('/contact-info')}
          >
            {text.PublicHeader.btn_help}
          </Button>}
        </div>
      </div>
    );
  }
  
}

export default withRouter(connect(select, { changeLanguage })(TranslationHandler(Header)));