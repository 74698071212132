import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import Header from 'view/public/Header';

import { updateAcctDetails } from 'view/public/CreateAccount/actions';

import { Button } from '@mui/material';

import EmailInput from 'utils/SmartForm/components/EmailInput';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

import styles from 'view/public/CreateAccount/ValidateEmail/ValidateEmailForm/index.module.css';

const select = state => ({
  details: state.accountDetails.details,
  isResident: state.session.isResident
});

export class ValidateEmailForm extends Component {

  static propTypes = {
    updateAcctDetails: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    isResident: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      ValidateEmail: PropTypes.shape({
        btn_goback: PropTypes.string,
        btn_send: PropTypes.string,
        lbl_email: PropTypes.string,
        txt_explanation: PropTypes.string,
        txt_title: PropTypes.string,
      }),
    }).isRequired,
  };
  
  state = {
    isLoading: false,
    emailSent: false,
  };

  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;
    return (
      <div className={styles.formContainer}>
        <LoadingOverlay
          show={this.state.isLoading}
          width='100%'
        > 
          <form onSubmit={this.forgotEmailSubmit}>
            <div className={styles.container}>
              <div className={styles.logo}>
                <Header />
              </div>
              <div className={styles.title}><p />{text.ValidateEmail.txt_title}</div>
              <div className={styles.explanation}>
                <p>{text.ValidateEmail.txt_explanation}</p>
              </div>
              <div className={styles.field}>
                <EmailInput
                  inputName='email'
                  isRequired={true}
                  labelText={text.ValidateEmail.lbl_email}
                  style={{ width: '300px', height: '75px' }}
                  autoComplete={'off'}
                  inputFocus={true}
                />
              </div>
              <div className={styles.btn}>
                <Button
                  type='submit'
                  style={{ width: '150px' }}
                  onClick={() => this.goBack()}
                >
                  {text.ValidateEmail.btn_goback}
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  style={{ width: '150px' }}
                  onClick={e => this.validateEmail(e)}
                >
                  {text.ValidateEmail.btn_send}
                </Button>
              </div>
            </div>
          </form>              
        </LoadingOverlay>
      </div>
    );
  }

  validateEmail(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedList = this.context.prepInputList(validObj.inputList);
      const details = cloneDeep(this.props.details);
      details.userDetails.email = formattedList.email;
      this.props.updateAcctDetails(details);
      const isResident = cloneDeep(this.props.isResident);
      if (isResident == true) {
        this.props.history.push('/resident/otp-password');
      } 
      else {
        this.props.history.push('/guest/otp-password');
      }
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }

  goBack() {
    const isResident = cloneDeep(this.props.isResident);
    if (isResident == true) {
      this.props.history.push('/resident/account-details');
    } 
    else {
      this.props.history.push('/guest/account-details');
    }
  }

}

export default withRouter(connect(select, {
  updateAcctDetails
})(TranslationHandler(ValidateEmailForm)));