import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

export class RadioSelectInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,

    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
        label: PropTypes.string,
        disabled: PropTypes.bool,
      })
    ).isRequired,
    row: PropTypes.bool // boolean to set Radios horizontal vs vertical
  };

  state = {
    inputValue: '',
    inputIndex: undefined,
    isError: undefined,
    helperMsg: ''
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const inputValue = this.context.inputList[this.state.inputIndex].inputValue;
    if (prevState.inputValue !== inputValue) {
      const e = { target: { value: inputValue } };
      this.inputChange(e);
    }

    const { isRequired } = this.props;
    if (prevProps.isRequired !== isRequired) { // for when the input requirement is updated dynamically
      this.context.updateInputRequirement(this.context.inputList, this.state.inputIndex, isRequired);
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const { 
      inputName, labelText, isDisabled, isReadOnly,
      inputFullWidth, className, style, options, row,
    } = this.props;

    const { inputValue, isError, helperMsg } = this.state;
    const errorMsg = (helperMsg) || this.context.helperTextManage(inputName);

    return (
      <FormControl
        component='fieldset'
        error={isError || this.context.helperErrorManage(inputName)}
        disabled={isDisabled}
        fullWidth={inputFullWidth}
        className={className}
        style={style}
      >
        {labelText &&
        <FormLabel component='legend'>{labelText}</FormLabel>}
        <RadioGroup
          value={inputValue || ''}
          onChange={this.inputChange}
          disabled={isDisabled}
          row={row}
          aria-label={labelText}
          name='radio-buttons-group'
        >
          {options.map(({ value, label, disabled }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio disableRipple={isReadOnly} />}
              label={label || value}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
        {errorMsg && 
        <FormHelperText style={{ textAlign: 'center' }}>{errorMsg}</FormHelperText>}
      </FormControl>
    );
  }

  inputChange = e => {
    let inputValue = e.target.value || '';

    const { inputName, isRequired } = this.props;
    let helperMsg = '';
    let isError = undefined;
    
    if (inputValue.length === 0) {
      inputValue = undefined;
      if (isRequired) {
        isError = true;
        helperMsg = 'This field is required';
      }
    }
    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  };
  
}

RadioSelectInput.defaultProps = {
  row: true,
};

export default RadioSelectInput;