import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import {
  TextField,
  InputAdornment,
} from '@mui/material';

import {
  IconBtnTooltip,
} from '@timber-rider/parkingtree-client-common';

export class PasswordInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,
  };

  state = {
    inputValue: '',
    isError: undefined,
    helperMsg: '',

    isPasswordVisible: false,
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const inputValue = this.context.inputList[this.state.inputIndex].inputValue;
    if (prevState.inputValue !== inputValue) {
      this.setState({ inputValue });
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const { 
      inputName, labelText, isDisabled, isReadOnly, inputFocus,
      inputFullWidth, autoComplete, className, style,
    } = this.props;

    const { inputValue, isError, helperMsg, isPasswordVisible } = this.state;
    
    return (
      <TextField
        name={inputName}
        type={isPasswordVisible ? 'text' : 'password'}
        value={inputValue}
        label={labelText}
        disabled={isDisabled}
        helperText={helperMsg || this.context.helperTextManage(inputName)}
        error={isError || this.context.helperErrorManage(inputName)}
        autoFocus={inputFocus || false}
        fullWidth={inputFullWidth || false}
        autoComplete={autoComplete || 'on'}
        style={style}
        className={className}
        variant='outlined'
        onChange={e => this.inputChange(e)}
        inputProps={{
          maxLength: 200,
        }}
        InputProps={{ 
          readOnly: isReadOnly,
          endAdornment: (
            <InputAdornment position='end'>
              <IconBtnTooltip
                icon={isPasswordVisible ? 'visibility' : 'visibilityOff'}
                onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                title='Toggle Password'
              />
            </InputAdornment>
          )
        }}
        key={inputName}
        data-testid={inputName}
      />
    );
  }

  inputChange = e => {
    let inputValue = e.target.value.trimStart() || '';
    // eslint-disable-next-line no-irregular-whitespace
    
    const { inputName } = this.props;
    const helperMsg = '';
    const isError = undefined;
    
    if (inputValue.length === 0) {
      inputValue = '';
    }
    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  };

  isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
  
}

export default PasswordInput;