import { cloneDeep } from 'lodash';

import {
  ADD_ALERT,
  CLEAR_ALERTS,
  REMOVE_ALERT,
} from 'utils/components/alerts/constants';

const initialState = {
  alertList: [],
};

export default function Alerts(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {

    case ADD_ALERT: {
      const alert = action.payload;
      newState.alertList.push({ id: alert.id, severity: alert.severity, message: alert.message });
      return newState;
    }

    case CLEAR_ALERTS: {
      newState.alertList = [];
      return newState;
    }

    case REMOVE_ALERT: {
      const id = action.payload;
      const hasAlert = newState.alertList.some(alert => alert.id === id);
      if (hasAlert) {
        newState.alertList.splice(newState.alertList.findIndex(alert => alert.id === id), 1);
      }
      return newState;
    }

    default: 
      return newState;
  }
}