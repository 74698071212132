import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom( // eslint-disable-line
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      valueIsNumericString
      {...other}
      getInputRef={ref}
      onValueChange={onChange}
    />
  );
});

NumericFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const PatternFormatCustom = React.forwardRef(function PatternFormatCustom( // eslint-disable-line
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={onChange}
    />
  );
});

PatternFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default class BaseNumberInput extends React.Component {
  static propTypes = {
    ...NumericFormat.propTypes,
    ...PatternFormat.propTypes,
    errorText: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    inputProps: PropTypes.object,
  };
  
  static defaultProps = {
    errorText: '',
    className: '',
    isintl: 'false',
    isNumericFormat: true,
  };

  render() {
    const { isNumericFormat, errorText, className, style, InputProps, inputProps, ...etc } = this.props;
    return (
      <TextField
        className={className}
        error={Boolean(errorText)}
        helperText={errorText}
        style={style}
        InputProps={{
          ...InputProps,
          inputComponent: isNumericFormat ? NumericFormatCustom : PatternFormatCustom,
        }}
        inputProps={inputProps}
        {...etc}
      />
    );
  }

  static extendProps(componentClass) {
    componentClass.propTypes = {
      ...BaseNumberInput.propTypes,
      ...componentClass.propTypes,
    };
    componentClass.defaultProps = {
      ...BaseNumberInput.defaultProps,
      ...componentClass.defaultProps,
    };
  }
}
