import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import Header from 'view/public/Header';

import PasswordInput from 'utils/SmartForm/components/PasswordInput';

import { PasswordRequirements } from '@timber-rider/parkingtree-client-common';

import {
  Button,
  Slide,
} from '@mui/material';

import styles from 'view/public/ResetPassword/ResetPasswordForm/index.module.css';

const select = (state) => ({
  passwordRequirements: state.session.passwordRequirements,
});

export class ResetPasswordForm extends Component {
  
  static propTypes = {
    passwordRequirements: PropTypes.array.isRequired,
    text: PropTypes.shape({
      ResetPassword: PropTypes.shape({
        btn_reset: PropTypes.string,
        lbl_password:  PropTypes.string,
        txt_message:  PropTypes.string,
        txt_title:  PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isPasswordValid: false,
    pass: undefined,
  };

  static contextType = SmartFormContext;

  componentDidUpdate(prevProps, prevState) {
    const inputList = this.context.inputList;
    const pass = inputList.find(input => input.inputName === 'pass');
    if (pass && pass.inputValue !== prevState.pass) {
      if (pass) this.setState({ pass: pass.inputValue });
    }
  }
  
  render() {
    const { text, passwordRequirements } = this.props;
    const { isPasswordValid, pass } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.header}><Header /></div>
        <div className={styles.pageTitle}>{text.ResetPassword.txt_title}</div>
        <div className={styles.message}>{text.ResetPassword.txt_message}</div>
        <div className={styles.password}>
          <PasswordInput
            inputName='pass'
            isRequired={true}
            labelText={text.ResetPassword.lbl_password}
            style={{ width: '300px' }}
          />
        </div>
        <div className={styles.passwordRequirements}>
          <div style={{ margin: 'auto', width: '300px' }}>
            <PasswordRequirements
              password={pass || ''}
              passwordRequirements={passwordRequirements}
              onPasswordCheck={isValid => this.setState({ isPasswordValid: isValid })}
            />
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            variant='contained'
            style={{ width: '200px' }}
            disabled={!isPasswordValid}
            onClick={() => null}
          >
            {text.ResetPassword.btn_reset}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(select, {})(TranslationHandler(ResetPasswordForm)));

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});