import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import ResetPasswordForm from 'view/public/ResetPassword/ResetPasswordForm';

export class ResetPassword extends Component {

  render() {
    return (
      <SmartFormProvider>
        <ResetPasswordForm />
      </SmartFormProvider>
    );
  }

}

export default ResetPassword;