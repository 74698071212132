import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';

import { 
  Button,
  IconButton,
} from '@mui/material';

import { togglePermitAdd } from 'view/actions';
import { Modal } from '@timber-rider/parkingtree-client-common';

import CloseIcon from '@mui/icons-material/Close';

import styles from 'view/private/body/resident/PermitSelect/index.module.css';

export class PermitSelect extends Component {
  
  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      PermitSelect: PropTypes.shape({
        btn_change: PropTypes.string,
        txt_your_community: PropTypes.string,
        txt_choose_permit: PropTypes.string,
        txt_guest_permit: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_ok: PropTypes.string,
        lbl_confirm_exit: PropTypes.string,
        txt_are_sure: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmExit: false,
    availablePermits: [
      { id: 0, name: 'Assigned Covered', link: '/resident/vehicle-add' },
      { id: 1, name: 'Open Lot', link: null }
    ],
  };
  
  render() {
    const { text } = this.props;
    const { availablePermits } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.closeBtn}>
          <IconButton onClick={() => {
            this.setState({ confirmExit: true });
          }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.communityLbl}>{text.PermitSelect.txt_your_community}:</div>
        <div className={styles.communityName}>
          <div style={{ alignSelf: 'center' }}>River Heights</div>
        </div>
        <div className={styles.choosePermitType}>{text.PermitSelect.txt_choose_permit}:</div>
        <div className={styles.availablePermitTypeList}>
          {availablePermits?.map(permit => (
            <React.Fragment key={permit.id}>
              <Button
                variant='outlined'
                style={{ margin: '5px' }}
                onClick={() => permit.link ? this.props.history.push(permit.link) : null}
              >
                {permit.name}
              </Button><br />
            </React.Fragment>
          ))}
        </div>
        <div className={styles.chooseGuestPermit}>Choose a Guest Permit:</div>
        <div className={styles.guestPermitBtn}>
          <Button
            variant='outlined'
            style={{ margin: '5px' }}
            onClick={() => this.props.history.push('/resident/permit-info')}
          >
            Open Lot
          </Button><br />
          <Button
            variant='outlined'
            style={{ margin: '5px' }}
            onClick={() => this.props.history.push('/resident/permit-info')}
          >
            Covered
          </Button>
        </div>
        {this.confirmExit()}
      </div>
    );
  }

  confirmExit() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.PermitSelect.btn_cancel,
        action: () => this.setState({ confirmExit: false }),
        buttonType: 'text',
      },
      {
        label: text.PermitSelect.btn_ok,
        action: () => {
          this.props.togglePermitAdd(false);
          this.props.history.push('/resident');
        },
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmExit: false })}
        show={this.state.confirmExit}
        title={text.PermitSelect.lbl_confirm_exit}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.PermitSelect.txt_are_sure}
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(null, { togglePermitAdd })(TranslationHandler(PermitSelect)));