import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';

import { coreColors } from 'utils/theme/parking_tree';

// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsIcon from '@mui/icons-material/Notifications';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import SessionMenu from 'view/private/footer/SessionMenu';

import { userLogout } from 'view/actions';

import { addAlert } from 'utils/components/alerts/actions';

const select = state => ({
  isAddingPermit: state.session.isAddingPermit,
  isResident: state.session.isResident,
});

export class Footer extends Component {

  static propTypes = {
    userLogout: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    isAddingPermit: PropTypes.bool.isRequired,
    isResident: PropTypes.bool.isRequired,
    handleSessionMenu: PropTypes.func.isRequired,
    closeSessionMenu: PropTypes.func.isRequired,
    isSessionOpen: PropTypes.bool.isRequired,
  };

  state = {
    menuSelected: '',
  };

  render() {
    if (this.props.isAddingPermit) {
      return null;
    }
    
    const { menuSelected } = this.state;
    
    return (
      <React.Fragment>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            value={menuSelected} 
            onChange={menuSelected => this.setState({ menuSelected })}
            style={{ backgroundColor: coreColors.primary }}
          >
            <BottomNavigationAction
              value={menuSelected}
              icon={<NotificationsIcon style={{ fontSize: '2.0rem' }} />}
              style={{ color: '#fff' }}
              onClick={() => {
                this.props.closeSessionMenu();
                this.notificationRouter();
              }}
            />
            <BottomNavigationAction
              value={menuSelected}
              icon={<HomeIcon style={{ fontSize: '2.0rem' }} />}
              style={{ color: '#fff' }}
              onClick={() => {
                this.props.closeSessionMenu();
                this.homeRouter();
              }}
            />
            <BottomNavigationAction
              value={menuSelected}
              icon={<AccountCircleIcon style={{ fontSize: '2.0rem' }} />}
              style={{ color: '#fff' }}
              onClick={() => this.handleMenu('isSessionOpen')}
            />
          </BottomNavigation>          
        </Paper>
        <SessionMenu 
          isSessionOpen={this.props.isSessionOpen}
          handleSessionMenu={() => this.props.handleSessionMenu()}
          logOut={() => this.logOut()}
        />
      </React.Fragment>
    );
  }

  logOut() {
    this.props.userLogout();
    this.props.history.push('/');
    this.props.addAlert('success', 'You have successfully logged out.');
  }

  handleMenu(selection) {
    const currentSelection = this.state[selection];
    this.setState({ [selection]: !currentSelection });
    this.props.handleSessionMenu();
  }

  homeRouter() {
    const isResident = cloneDeep(this.props.isResident);
    if (isResident == true) {
      this.props.history.push('/resident');
    } 
    else {
      this.props.history.push('/guest');
    }
  }

  notificationRouter() {
    const isResident = cloneDeep(this.props.isResident);
    if (isResident == true) {
      this.props.history.push('/resident/notification');
    } 
    else {
      this.props.history.push('/guest/notification');
    }
  }
}

export default connect(select, {
  userLogout,
  addAlert,
})(withRouter(Footer));