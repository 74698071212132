import React, { Component } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';

import GuestHome from 'view/private/body/guest/Home';
import PermitSelect from 'view/private/body/guest/PermitSelect';
import PermitInfo from 'view/private/body/guest/PermitInfo';
import VehicleAdd from 'view/private/body/guest/VehicleAdd';
import PaymentAdd from 'view/private/body/guest/PaymentAdd';
import VehicleEdit from 'view/private/body/guest/VehicleEdit';
import Documents from 'view/private/body/guest/Documents';
import VehicleChange from 'view/private/body/guest/VehicleChange';
import Notification from 'view/private/body/guest/Notification';
import AccountInfo from 'view/private/body/guest/AccountInfo';
import AccountInfoEdit from 'view/private/body/guest/AccountInfoEdit';
import SendOTP from 'view/private/body/guest/SendOTP';
import ValidateOTP from 'view/private/body/guest/ValidateOTP';
import ChangePassword from 'view/private/body/guest/ChangePassword';
import Contact from 'view/private/body/guest/Contact';
import SelectUnit from 'view/private/body/guest/SelectUnit';
import BillingInfo from 'view/private/body/guest/BillingInfo';
import PermitVehicle from 'view/private/body/guest/PermitVehicle';


export class Guest extends Component {

  render() {
    return (
      <Switch>
        <Route exact path='/guest' render={props => (<GuestHome {...props} />)} />
        <Route exact path='/guest/documents' render={props => (<Documents {...props} />)} />
        <Route exact path='/guest/permit-select' render={props => (<PermitSelect {...props} />)} />
        <Route exact path='/guest/permit-info' render={props => (<PermitInfo {...props} />)} />
        <Route exact path='/guest/vehicle-add' render={props => (<VehicleAdd {...props} />)} />
        <Route exact path='/guest/payment-add' render={props => (<PaymentAdd {...props} />)} />
        <Route exact path='/guest/vehicle-edit/:id' render={props => (<VehicleEdit {...props} />)} />
        <Route exact path='/guest/vehicle-change/:id' render={props => (<VehicleChange {...props} />)} />
        <Route exact path='/guest/notification' render={props => (<Notification {...props} />)} />
        <Route exact path='/guest/account-info' render={props => (<AccountInfo {...props} />)} />
        <Route exact path='/guest/account-info-edit/:id' render={props => (<AccountInfoEdit {...props} />)} />
        <Route exact path='/guest/send-otp' render={props => (<SendOTP {...props} />)} />
        <Route exact path='/guest/validate-otp' render={props => (<ValidateOTP {...props} />)} />
        <Route exact path='/guest/change-password' render={props => (<ChangePassword {...props} />)} />
        <Route exact path='/guest/contact' render={props => (<Contact {...props} />)} />
        <Route exact path='/guest/select-unit' render={props => (<SelectUnit {...props} />)} />
        <Route exact path='/guest/billing-info' render={props => (<BillingInfo {...props} />)} />
        <Route exact path='/guest/permit-vehicle/:id' render={props => (<PermitVehicle {...props} />)} />
        <Route exact path='/guest/payment-add/:id' render={props => (<PaymentAdd {...props} />)} />
      </Switch>
    );
  }
}

export default withRouter(Guest);