// import axios from 'axios';

export function persistErrors(error) {
  alert(error);
  // const smartInputError = `Smart Input Error: ${error}`;
  // axios.put(`${window.frontendConfig.api}/error`, smartInputError)
  //   .then(() => {
  //     if (process.env.NODE_ENV !== 'test') {
  //       alert('ATTENTION: Inside Smart Input Error Handler, endPoint not working, params = ', smartInputError); // eslint-disable-line no-console
  //     }
  //   })  
  //   .catch(() => {
  //     if (process.env.NODE_ENV !== 'test') {
  //       console.log('ATTENTION: Inside Smart Input Error Handler, endPoint not working, params = ', smartInputError); // eslint-disable-line no-console
  //     }
  //   });
}