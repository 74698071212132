import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';

import { Button } from '@mui/material';

import styles from 'view/private/body/guest/SendOTP/index.module.css';

export class SendOTP extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      SendOTP: PropTypes.shape({
        btn_send_otp: PropTypes.string,
        lbl_email: PropTypes.string,
        txt_explanation: PropTypes.string,
        txt_page_title: PropTypes.string,
        txt_warning: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.SendOTP.txt_page_title}</div>
        <div className={styles.sendEmailMsg}>
          {text.SendOTP.txt_explanation}<br /><br />
          {text.SendOTP.txt_warning}
        </div>
        <div className={styles.emailLbl}>{text.SendOTP.lbl_email}:</div>
        <div className={styles.email}>b@a.com</div>
        <div className={styles.sendBtn}>
          <Button
            variant='contained'
            style={{ width: '300px' }}
            onClick={e => this.sendOTP(e)}
          >
            {text.SendOTP.btn_send_otp}
          </Button>
        </div>
      </div>
    );
  }

  sendOTP() {
    this.props.history.push('/guest/validate-otp');
  }
}

export default withRouter(TranslationHandler(SendOTP));