import React from 'react';
import PropTypes from 'prop-types';
import BaseNumber from 'utils/components/BaseNumber';

function CreditCardNumber(props) {
  return (
    <BaseNumber
      inputProps={{
        format: props.inputProps.format, // ex: Visa '#### #### #### ####'
        mask: '_',
      }}
      {...props}
    />
  );
}

BaseNumber.extendProps(CreditCardNumber);

export default CreditCardNumber;

CreditCardNumber.propTypes = {
  inputProps: PropTypes.object,
  isintl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  value: PropTypes.string
};