import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import CreatePasswordForm from 'view/public/CreateAccount/CreatePassword/CreatePasswordForm';

export class CreatePassword extends Component {

  render() {
    return (
      <SmartFormProvider>
        <CreatePasswordForm />
      </SmartFormProvider>
    );
  }

}

export default CreatePassword;