import { v4 as uuidv4 } from 'uuid';
import {
  ADD_ALERT,
  CLEAR_ALERTS,
  REMOVE_ALERT,
} from 'utils/components/alerts/constants';

export function addAlert(severity, message) {
  const id = uuidv4();
  return {
    type: ADD_ALERT,
    payload: { id, severity, message }
  };
}

export function clearAlerts() {
  return {
    type: CLEAR_ALERTS,
  };
}

export function removeAlert(id) {
  return {
    type: REMOVE_ALERT,
    payload: id,
  };
}