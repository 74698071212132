import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import SearchPropertyForm from 'view/public/CreateAccount/SearchProperty/SearchPropertyForm';

export class SearchProperty extends Component {

  render() {
    return (
      <SmartFormProvider>
        <SearchPropertyForm />
      </SmartFormProvider>
    );
  }

}

export default SearchProperty;