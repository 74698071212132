import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { Button } from '@mui/material';

import TranslationHandler from 'utils/translations/translation_handler';
import DropdownInput from 'utils/SmartForm/components/DropdownInput';
import RadioSelectInput from 'utils/SmartForm/components/RadioSelectInput';
import DatePickerInput from 'utils/SmartForm/components/DatePickerInput';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import styles from 'view/private/body/guest/PermitInfo/PermitInfoForm/index.module.css';

const units = [
  { id: 0, units: '101' },
  { id: 1, units: '102' },
  { id: 2, units: '103' },
  { id: 3, units: '104' },
  { id: 4, units: '105' },  
  { id: 5, units: '106' },
  { id: 6, units: '107' },
  { id: 7, units: '108' },
  { id: 8, units: '109' },
];

const hours = [
  { id: 0, option: '1 hour - $3' },
  { id: 1, option: '2 hours - $4' },
  { id: 2, option: '3 hours - $5' },
  { id: 3, option: '4 hours - $6' },
  { id: 4, option: '5 hours - $7' },
  { id: 5, option: '6 hours - $8' },
];

const days = [
  { id: 0, option: '1 day - $10' },
  { id: 1, option: '2 days - $12' },
  { id: 2, option: '3 days - $14' },
  { id: 3, option: '4 days - $16' },
  { id: 4, option: '5 days - $18' },
  { id: 5, option: '6 days - $20' },
  { id: 6, option: '7 days - $22' },
  { id: 7, option: '8 days - $24' },
  { id: 8, option: '9 days - $26' },
  { id: 9, option: '10 days - $28' },
  { id: 10, option: '11 days - $30' },
  { id: 11, option: '12 days - $32' },
  { id: 12, option: '13 days - $34' },
  { id: 13, option: '14 days - $36' },
  { id: 14, option: '15 days - $38' },
  { id: 15, option: '16 days - $40' },
  { id: 16, option: '17 days - $42' },
  { id: 17, option: '18 days - $44' },
  { id: 18, option: '19 days - $46' },
  { id: 19, option: '20 days - $48' },
  { id: 20, option: '21 days - $50' },
  { id: 21, option: '22 days - $52' },
  { id: 22, option: '23 days - $54' },
  { id: 23, option: '24 days - $56' },
  { id: 24, option: '25 days - $58' },
  { id: 25, option: '26 days - $60' },
  { id: 26, option: '27 days - $62' },
  { id: 27, option: '28 days - $64' },
  { id: 28, option: '29 days - $66' },
  { id: 29, option: '30 days - $68' },
  { id: 30, option: '31 days - $70' },
];

const radioOption = [
  { lable: 'Hours', value: 'Hours' },
  { lable: 'Days', value: 'Days' }
];


export class PermitInfoForm extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      GuestPermitInfo: PropTypes.shape({
        btn_next: PropTypes.string,
        btn_goback: PropTypes.string,
        txt_title: PropTypes.string,
        lbl_unit: PropTypes.string,
        txt_permit_question: PropTypes.string,
        lbl_hour: PropTypes.string,
        lbl_day: PropTypes.string,
        lbl_day_visit: PropTypes.string,
        lbl_hour_visit: PropTypes.string,
      }),
    }).isRequired,
  };

  static contextType = SmartFormContext;


  componentDidMount() {
    const inputList = cloneDeep(this.context.inputList);
    inputList.forEach(input => {
      if (input.inputName === 'frequency') {
        input.inputValue = 'Hours';
      }
    });
    this.context.updateInputList(inputList);
  }
  
  render() {
    const { text } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.GuestPermitInfo.txt_title}</div>
        <div className={styles.unitNumber}>
          <DropdownInput
            inputName='unit'
            isRequired={true}
            labelText={text.GuestPermitInfo.lbl_unit}
            style={{ width: '100%', height: '75px' }}
            options={units.map(unit => ({
              value: unit.id,
              display: unit.units,
            }))}
          />
        </div>
        <div className={styles.radioSelect}>
          <div>
            {text.GuestPermitInfo.txt_permit_question}
          </div>
          <RadioSelectInput
            inputName='frequency'
            isRequired={true}
            row={false}
            options={radioOption}
          />
        </div>
        <div className={styles.timeDropdown}>
          <DatePickerInput 
            inputName='exampleDatePicker'
            isRequired={true}
            labelText='Date Picker'
            style={{ width: '100%', height: '75px' }}
          />
          <DropdownInput
            inputName='dayOrHours'
            isRequired={true}
            labelText={this.radioSelectOptions().text}
            style={{ width: '100%', height: '75px' }}
            options={
              this.radioSelectOptions().options.map(input => ({
                value: input.id,
                display: input.option,
              }))}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            style={{ width: '100px' }}
            onClick={() => this.props.history.goBack()}
          >
            {text.GuestPermitInfo.btn_goback}
          </Button>
          <Button
            variant='contained'
            style={{ width: '125px' }}
            onClick={() => this.sendPermitInfo()}
          >
            {text.GuestPermitInfo.btn_next}
          </Button>
        </div>
      </div>
    );
  }

  sendPermitInfo() {
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      // const formattedList = this.context.prepInputList(validObj.inputList);
      this.props.history.push('/guest/vehicle-add');
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }

  
  radioSelectOptions() {
    const { text } = this.props;
    const list = cloneDeep(this.context.inputList);
    const lenght = Object.keys(list).length;
    if (lenght !== 0) {
      if (list[1].inputValue == 'Hours') {
        return (
          { text: text.GuestPermitInfo.lbl_hour_visit, options: hours }
        );
      }
      else {
        return (
          { text: text.GuestPermitInfo.lbl_day_visit, options: days }
        );
      }
    } 
    else {
      return (
        { text: text.GuestPermitInfo.lbl_day_visit, options: days }
      );
    }
  }
}

export default withRouter(TranslationHandler(PermitInfoForm));
