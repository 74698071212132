import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import TranslationHandler from 'utils/translations/translation_handler';

import { 
  Box,
  List,
  ListItem,
  Divider,
  Slide,
} from '@mui/material';

import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';

import styles from 'view/private/footer/index.module.css';

const select = state => ({
  isResident: state.session.isResident,
});

export class SettingsMenu extends Component {
  
  static propTypes = {
    isSessionOpen: PropTypes.bool.isRequired,
    handleSessionMenu: PropTypes.func.isRequired,
    isResident: PropTypes.bool.isRequired,
    logOut: PropTypes.func.isRequired,
    text: PropTypes.shape({
      SessionMenu: PropTypes.shape({
        lbl_documents: PropTypes.string,
        lbl_account_info: PropTypes.string,
        lbl_logout: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    return this.props.isSessionOpen ? this.renderSettingsMenu() : null;
  }

  renderSettingsMenu() {
    const { isSessionOpen, text } = this.props;
    return (
      <Box sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper', position: 'fixed', bottom: '56px' }} id='topBox'>
        <Slide direction='up' in={isSessionOpen}>
          <List disablePadding>
            <Divider />
            <ListItem>
              <div className={styles.subNav} onClick={() => {
                this.props.handleSessionMenu();
                this.documentRouter();
              }}
              >
                <div className={styles.subNavBtn}>
                  <DescriptionIcon color='primary' />
                </div>
                <div className={styles.subNavLabel}>{text.SessionMenu.lbl_documents}</div>
              </div>
            </ListItem>
            <ListItem>
              <div className={styles.subNav} onClick={() => {
                this.props.handleSessionMenu();
                this.accountInfoRouter();
              }}
              >
                <div className={styles.subNavBtn}>
                  <LocalAtmIcon color='primary' />
                </div>
                <div className={styles.subNavLabel}>{text.SessionMenu.lbl_account_info}</div>
              </div>
            </ListItem>
            <Divider />
            <ListItem>
              <div className={styles.subNav} onClick={() => this.props.logOut()}>
                <div className={styles.subNavBtn}>
                  <LogoutIcon color='primary' />
                </div>
                <div className={styles.subNavLabel}>{text.SessionMenu.lbl_logout}</div>
              </div>
            </ListItem>
          </List>
        </Slide>
      </Box>
    );
  }

  documentRouter() {
    const isResident = cloneDeep(this.props.isResident);
    if (isResident == true) {
      this.props.history.push('/resident/Documents');
    } 
    else {
      this.props.history.push('/guest/Documents');
    }
  }

  accountInfoRouter() {
    const isResident = cloneDeep(this.props.isResident);
    if (isResident == true) {
      this.props.history.push('/resident/account-info');
    } 
    else {
      this.props.history.push('/guest/account-info');
    }
  }

}

//export default withRouter(TranslationHandler(SettingsMenu));
export default withRouter(connect(select, null)(TranslationHandler(SettingsMenu)));