import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { IconButton, Button } from '@mui/material';

import TranslationHandler from 'utils/translations/translation_handler';

import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@timber-rider/parkingtree-client-common';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import VehicleEditForm from 'view/private/body/guest/VehicleEdit/VehicleEditForm';
import DropdownInput from 'utils/SmartForm/components/DropdownInput';

import { togglePermitAdd } from 'view/actions';

import styles from 'view/private/body/guest/VehicleEdit/index.module.css';

export class VehicleEdit extends Component {

  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      VehicleEdit: PropTypes.shape({
        txt_add_vehicle: PropTypes.string,
        txt_are_sure: PropTypes.string,
        btn_ok: PropTypes.string,
        btn_cancel: PropTypes.string,
        lbl_confirm_exit: PropTypes.string,
        txt_change_vehicle: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmExit: false,
    hasVehicle: false,
    isAddVehicle: true,
  };

  render() {
    const { text } = this.props;
    const { hasVehicle, isAddVehicle } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.closeBtn}>
          <IconButton onClick={() => {
            this.setState({ confirmExit: true });
          }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <SmartFormProvider>
          {hasVehicle ?
            <React.Fragment>
              <div className={styles.chooseVehicle}>
                <DropdownInput
                  inputName='myVehicle'
                  isRequired={true}
                  labelText='Choose Existing Vehicle'
                  style={{ width: '90%', height: '75px' }}
                  options={vehicles.map(VerticalShadesClosedRounded => ({
                    value: VerticalShadesClosedRounded.id,
                    display: VerticalShadesClosedRounded.name,
                  }))}
                />
              </div>
              <div className={styles.orAddVehicle}>
                {text.VehicleEdit.txt_add_vehicle}
              </div>
            </React.Fragment>
            :
            <div className={styles.editOther}>         
              <Button
                variant='contained'
                onClick={() => this.props.history.push('/guest/vehicle-change/0')}
              >
                {text.VehicleEdit.txt_change_vehicle}
              </Button>
            </div>
          }
          {isAddVehicle ?
            <div className={styles.form}>         
              <VehicleEditForm />
            </div>
            :
            <div className={styles.form}>         
              <Button
                variant='contained'
                onClick={() => this.setState({ isAddVehicle: false, hasVehicle: true })}
              >
                add new vehicle
              </Button>
            </div>
          }
          {this.confirmExit()}
        </SmartFormProvider>
      </div>
    );
  }

  confirmExit() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.VehicleEdit.btn_cancel,
        action: () => this.setState({ confirmExit: false }),
        buttonType: 'text',
      },
      {
        label: text.VehicleEdit.btn_ok,
        action: () => {
          this.props.togglePermitAdd(false);
          this.props.history.push('/guest');
        },
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmExit: false })}
        show={this.state.confirmExit}
        title={text.VehicleEdit.lbl_confirm_exit}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.VehicleEdit.txt_are_sure}
        </div>
      </Modal>
    );
  }
}

export default connect(null, { togglePermitAdd })(TranslationHandler(VehicleEdit));

const vehicles = [
  { id: 0, name: 'Tundra' },
  { id: 2, name: 'Rogue' },
];