import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TranslationHandler from 'utils/translations/translation_handler';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import ValidateOTPForm from 'view/private/body/resident/ValidateOTP/ValidateOTPForm';

import styles from 'view/private/body/resident/ValidateOTP/index.module.css';

export class ValidateOTP extends Component {

  static propTypes = {
    text: PropTypes.shape({
      ValidateOTP: PropTypes.shape({
        txt_explanation: PropTypes.string,
        txt_title: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}><p />{text.ValidateOTP.txt_title}</div>
        <div className={styles.explanation}>
          <p>{text.ValidateOTP.txt_explanation}</p>
        </div>
        <div className={styles.form}>
          <SmartFormProvider>
            <ValidateOTPForm />
          </SmartFormProvider>
        </div>
      </div>
    );
  }

}

export default TranslationHandler(ValidateOTP);