import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';

import TranslationHandler from 'utils/translations/translation_handler';

import { 
  Button,
  Menu,
  MenuItem,
  Fade,
} from '@mui/material';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { changeLanguage } from 'view/actions';
import { LANGUAGES } from 'view/constants';

import styles from 'view/private/header/index.module.css';
import { coreColors } from 'utils/theme/parking_tree';

const select = state => ({
  language: state.session.language,
  isAddingPermit: state.session.isAddingPermit,
  isResident: state.session.isResident,
});

export class PrivateHeader extends Component {

  static propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    isAddingPermit: PropTypes.bool.isRequired,
    isResident: PropTypes.bool.isRequired,
    handleSessionMenu: PropTypes.func.isRequired,
    isSettingMenuOpen: PropTypes.bool.isRequired,
  };

  state = {
    isLoading: false,
    anchorEl: null,
    lang: 'ENGLISH',
  };

  render() {
    if (this.props.isAddingPermit) {
      return null;
    }
    
    const { isSettingMenuOpen } = this.props;
    const blurStyles = isSettingMenuOpen ? 'blur(2px)' : 'unset' ;
    const { anchorEl, lang } = this.state;
    return (
      <div
        className={styles.container}
        style={{ filter: blurStyles }}
        onClick={this.props.handleSessionMenu}
      >
        <div className={styles.logo}>
          <img src={ require('utils/media/emblem.png') } alt='The Parking Tree' height='40px' />
        </div>
        <div className={styles.language}>
          <Button 
            onClick={() => null /* //e => this.setState({ anchorEl: e.currentTarget }) */}
            style={{ color: coreColors.primary }}
          >
            {lang}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => this.setState({ anchorEl: null })}
            TransitionComponent={Fade}
            style={{ top: '25px' }}
          >
            <MenuItem 
              onClick={() => {
                this.setState(
                  { anchorEl: null, lang: 'ENGLISH' },
                  () => this.props.changeLanguage(LANGUAGES.EN)
                );
              }}
            >ENGLISH</MenuItem>
            <MenuItem onClick={() => {
              this.setState(
                { anchorEl: null, lang: 'ESPAÑOL' },
                () => this.props.changeLanguage(LANGUAGES.ES)
              );
            }}
            >ESPAÑOL</MenuItem>
          </Menu>
        </div>
        <div className={styles.help}>
          <HelpOutlineIcon
            style={{ color: coreColors.primary, fontSize: '30px' }}
            onClick={() => this.contactRouter()}
          />
        </div>
      </div>
    );
  }

  contactRouter() {
    const isResident = cloneDeep(this.props.isResident);
    if (isResident == true) {
      this.props.history.push('/resident/contact');
    } 
    else {
      this.props.history.push('/guest/contact');
    }
  }
  
}

export default withRouter(connect(select, { changeLanguage })(TranslationHandler(PrivateHeader)));