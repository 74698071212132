import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Route } from 'react-router-dom';

import Login from 'view/public/Login';
import ForgotPassword from 'view/public/ForgotPassword';
import ForgotEmail from 'view/public/ForgotEmail';
import SearchProperty from 'view/public/CreateAccount/SearchProperty';
import UserTypeSelection from 'view/public/CreateAccount/UserTypeSelection';
import AccountDetails from 'view/public/CreateAccount/AccountDetails';
import AccountCreateConfirmation from 'view/public/CreateAccount/AccountCreateConfirmation';
import CreatePassword from 'view/public/CreateAccount/CreatePassword';
import ResetPassword from 'view/public/ResetPassword';
import ValidateEmail from 'view/public/CreateAccount/ValidateEmail';
import OneTimePassword from 'view/public/CreateAccount/OneTimePassword';
import ContactInfo from 'view/public/ContactInfo';

export class PublicRoutes extends Component {

  static propTypes = {
    isLoader: PropTypes.bool.isRequired,
  };

  render() {
    const pointerEvents = this.props.isLoader ? 'none' : 'auto';
    return (
      <div style={{ pointerEvents }}>
        <Switch>
          <Route exact path='/' render={props => (<Login {...props} />)} />
          
          <Route exact path='/forgot-password' render={props => (<ForgotPassword {...props} />)} />
          <Route exact path='/forgot-email' render={props => (<ForgotEmail {...props} />)} />
          <Route exact path='/reset-password' render={props => (<ResetPassword {...props} />)} />
          <Route exact path='/account-create-confirmation' render={props => (<AccountCreateConfirmation {...props} />)} />
          <Route exact path='/contact-info' render={props => (<ContactInfo {...props} />)} />

          <Route exact path='/search-property' render={props => (<SearchProperty {...props} />)} />
          <Route exact path='/user-type' render={props => (<UserTypeSelection {...props} />)} />

          <Route exact path='/account-details' render={props => (<AccountDetails {...props} />)} />
          <Route exact path='/validate-email' render={props => (<ValidateEmail {...props} />)} />
          <Route exact path='/otp-password' render={props => (<OneTimePassword {...props} />)} />
          <Route exact path='/create-password' render={props => (<CreatePassword {...props} />)} />

          <Route exact path='/resident/account-details' render={props => (<AccountDetails {...props} />)} />
          <Route exact path='/resident/validate-email' render={props => (<ValidateEmail {...props} />)} />
          <Route exact path='/resident/otp-password' render={props => (<OneTimePassword {...props} />)} />
          <Route exact path='/resident/create-password' render={props => (<CreatePassword {...props} />)} />

          <Route exact path='/guest/account-details' render={props => (<AccountDetails {...props} />)} />
          <Route exact path='/guest/validate-email' render={props => (<ValidateEmail {...props} />)} />
          <Route exact path='/guest/otp-password' render={props => (<OneTimePassword {...props} />)} />
          <Route exact path='/guest/create-password' render={props => (<CreatePassword {...props} />)} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PublicRoutes);