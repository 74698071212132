import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TranslationHandler from 'utils/translations/translation_handler';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import ContactForm from 'view/private/body/resident/Contact/ContactForm';

import styles from 'view/private/body/resident/Contact/index.module.css';

export class Contact extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      ContactInfo: PropTypes.shape({
        txt_contact_us: PropTypes.string,
        txt_dilbert: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.formBody}>
          <SmartFormProvider>
            <ContactForm />
          </SmartFormProvider>
        </div>
        <div className={styles.faq}>

          <div style={{ justifySelf: 'center' }}>
            <div style={{ fontSize: 'large', fontWeight: 'bold', marginTop: '20px' }}>ParkingTree FAQ:</div>
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                >                  
                  <strong>{text.ContactInfo.txt_contact_us}</strong>
                </AccordionSummary>
                <AccordionDetails>
                  {text.ContactInfo.txt_dilbert}
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default TranslationHandler(Contact);