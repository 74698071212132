import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import PasswordInput from 'utils/SmartForm/components/PasswordInput';

import styles from 'view/private/body/guest/ValidateOTP/ValidateOTPForm/index.module.css';

export class OneTimePasswordForm extends Component {

  static propTypes = {
    text: PropTypes.shape({
      ValidateOTP: PropTypes.shape({
        btn_submit: PropTypes.string,
        lbl_otp: PropTypes.string,
      }),
    }).isRequired,
  };
  
  state = {
    isLoading: false,
  };

  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;
    return (
      <form onSubmit={this.forgotEmailSubmit}>
        <div className={styles.container}>
          <div className={styles.field}>
            <PasswordInput
              inputName='otp'
              isRequired={true}
              labelText={text.ValidateOTP.lbl_otp}
              style={{ width: '300px', height: '75px' }}
              autoComplete={'off'}
              inputFocus={true}
            />
          </div>
          <div className={styles.btn}>
            <Button
              type='submit'
              variant='contained'
              style={{ width: '150px' }}
              onClick={e => this.provideOTP(e)}
            >
              {text.ValidateOTP.btn_submit}
            </Button>
          </div>
        </div>
      </form>
    );
  }

  provideOTP(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      // const formattedList = this.context.prepInputList(validObj.inputList);
      // alert(JSON.stringify(formattedList));
      this.props.history.push('/guest/change-password');
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }

}

export default withRouter(connect(null, {})(TranslationHandler(OneTimePasswordForm)));