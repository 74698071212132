import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import ForgotEmailForm from 'view/public/ForgotEmail/ForgotEmailForm';

export class ForgotEmail extends Component {

  render() {
    return (
      <SmartFormProvider>
        <ForgotEmailForm />
      </SmartFormProvider>
    );
  }

}

export default ForgotEmail;