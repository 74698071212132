import React, { Component } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';

import AccountInfo from 'view/private/body/resident/AccountInfo';
import AccountInfoEdit from 'view/private/body/resident/AccountInfoEdit';
import Documents from 'view/private/body/resident/Documents';
import Contact from 'view/private/body/resident/Contact';
import ChangePassword from 'view/private/body/resident/ChangePassword';
import ResidentHome from 'view/private/body/resident/Home';
import PermitSelect from 'view/private/body/resident/PermitSelect';
import VehicleAdd from 'view/private/body/resident/VehicleAdd';
import VehicleEdit from 'view/private/body/resident/VehicleEdit';
import PaymentAdd from 'view/private/body/resident/PaymentAdd';
import SendOTP from 'view/private/body/resident/SendOTP';
import ValidateOTP from 'view/private/body/resident/ValidateOTP';
import VehicleChange from 'view/private/body/resident/VehicleChange';
import Notification from 'view/private/body/resident/Notification';
import BillingInfo from 'view/private/body/resident/BillingInfo';
import PermitInfo from 'view/private/body/resident/PermitInfo';
import PermitVehicle from 'view/private/body/resident/PermitVehicle';

export class Resident extends Component {

  render() {
    return (
      <Switch>
        <Route exact path='/resident' render={props => (<ResidentHome {...props} />)} />
        <Route exact path='/resident/documents' render={props => (<Documents {...props} />)} />
        <Route exact path='/resident/contact' render={props => (<Contact {...props} />)} />
        <Route exact path='/resident/change-password' render={props => (<ChangePassword {...props} />)} />
        <Route exact path='/resident/account-info' render={props => (<AccountInfo {...props} />)} />
        <Route exact path='/resident/account-info-edit/:id' render={props => (<AccountInfoEdit {...props} />)} />
        <Route exact path='/resident/permit-select' render={props => (<PermitSelect {...props} />)} />
        <Route exact path='/resident/vehicle-add' render={props => (<VehicleAdd {...props} />)} />
        <Route exact path='/resident/vehicle-edit/:id' render={props => (<VehicleEdit {...props} />)} />
        <Route exact path='/resident/payment-add' render={props => (<PaymentAdd {...props} />)} />
        <Route exact path='/resident/send-otp' render={props => (<SendOTP {...props} />)} />
        <Route exact path='/resident/validate-otp' render={props => (<ValidateOTP {...props} />)} />
        <Route exact path='/resident/vehicle-change/:id' render={props => (<VehicleChange {...props} />)} />
        <Route exact path='/resident/notification' render={props => (<Notification {...props} />)} />
        <Route exact path='/resident/billing-info' render={props => (<BillingInfo {...props} />)} />
        <Route exact path='/resident/permit-info' render={props => (<PermitInfo {...props} />)} />
        <Route exact path='/resident/permit-vehicle/:id' render={props => (<PermitVehicle {...props} />)} />
        <Route exact path='/resident/payment-add/:id' render={props => (<PaymentAdd {...props} />)} />
      </Switch>
    );
  }
}

export default withRouter(Resident);