import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TranslationHandler from 'utils/translations/translation_handler';

import styles from 'view/private/body/resident/Documents/index.module.css';

export class Documents extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      Documents: PropTypes.shape({
        btn_addpermit: PropTypes.string,
        txt_community: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.Documents.txt_community}</div>
        <div className={styles.pageBody}>links to documents here.</div>
      </div>
    );
  }
}

export default TranslationHandler(Documents);