import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import { withRouter, Link } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';

import Header from 'view/public/Header';

import { updateUserType } from 'view/actions';

import { Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { updateAcctDetails } from 'view/public/CreateAccount/actions';
import { USER_TYPES } from 'view/public/CreateAccount/constants';

import { coreColors } from 'utils/theme/parking_tree';

import styles from 'view/public/CreateAccount/UserTypeSelection/index.module.css';

const select = state => ({
  details: state.accountDetails.details,
  isResident: state.session.isResident,
});

export class UserTypeSelection extends Component {
  
  static propTypes = {
    updateAcctDetails: PropTypes.func.isRequired,
    updateUserType: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    isResident: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      UserTypeSelection: PropTypes.shape({
        btn_resident: PropTypes.string,
        btn_guest: PropTypes.string,
        btn_goback: PropTypes.string,
        lbl_login: PropTypes.string,
        txt_your_community: PropTypes.string,
        txt_haveacct: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}><Header /></div>
          <div className={styles.property}>
            {text.UserTypeSelection.txt_your_community}: <b>River Heights</b>
          </div>
          <div className={styles.resident}>
            <Button
              variant='outlined'
              style={{ width: '200px' }}
              onClick={() => this.selectResident()}
            >
              {text.UserTypeSelection.btn_resident}
              <NavigateNextIcon />
            </Button>
          </div>
          <div className={styles.guest}>
            <Button
              variant='outlined'
              style={{ width: '200px' }}
              onClick={() => this.selectGuest()} 
            >
              {text.UserTypeSelection.btn_guest}
              <NavigateNextIcon />
            </Button>
          </div>
          <div className={styles.goback}>
            <Button
              variant='contained'
              style={{ width: '150px' }}
              onClick={() => this.props.history.push('/search-property')}
            >
              {text.UserTypeSelection.btn_goback}
            </Button>
          </div>
        </div>
        <div className={styles.haveAccount}>
          <p>
            {text.UserTypeSelection.txt_haveacct}
            <Link to='/' style={{ color: coreColors.primary, paddingLeft: '5px' }}>
              {text.UserTypeSelection.lbl_login}
            </Link>
          </p>
        </div>
      </>
    );
  }

  selectResident() {
    this.props.updateUserType(true);
    this.selectUserType(USER_TYPES.RESIDENT);
    this.props.history.push('/resident/account-details');
  }

  selectGuest() {
    this.props.updateUserType(false);
    this.selectUserType(USER_TYPES.GUEST);
    this.props.history.push('/guest/account-details');
  }

  selectUserType(type) {
    const details = cloneDeep(this.props.details);
    details.userType = type;
    this.props.updateAcctDetails(details);
  }
}

export default withRouter(connect(select, { 
  updateAcctDetails,
  updateUserType
})(TranslationHandler(UserTypeSelection)));