import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Route } from 'react-router-dom';

import Header from 'view/private/header';
import Footer from 'view/private/footer';

import Resident from 'view/private/body/resident';
import Guest from 'view/private/body/guest';

export class Private extends Component {

  static propTypes = {
    isLoader: PropTypes.bool.isRequired,
  };

  state = {
    isSettingMenuOpen: false,
    isSessionOpen: false,
  };

  render() {
    const { isSettingMenuOpen, isSessionOpen } = this.state;
    const blurStyles = isSettingMenuOpen ? 'blur(2px)' : 'unset' ;
    return (
      <React.Fragment>
        <Header isSettingMenuOpen={isSettingMenuOpen} handleSessionMenu={() => isSessionOpen ? this.handleSessionMenu() : null} />
        <div style={{ filter: blurStyles, height: '100vh' }} onClick={() => isSessionOpen ? this.handleSessionMenu() : null}>
          <Switch>
            <Route path='/resident' render={props => (<Resident {...props} />)} />
            <Route path='/guest' render={props => (<Guest {...props} />)} />
          </Switch>
        </div>
        <Footer 
          handleSessionMenu={() => this.handleSessionMenu()}
          closeSessionMenu={() => this.setState({ isSettingMenuOpen: false, isSessionOpen: false })}
          isSessionOpen={this.state.isSessionOpen}
        />
      </React.Fragment>
    );
  }

  handleSessionMenu() {
    this.setState({ isSettingMenuOpen: !this.state.isSettingMenuOpen, isSessionOpen: !this.state.isSessionOpen });
  }
}

export default withRouter(Private);