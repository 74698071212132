import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import PasswordInput from 'utils/SmartForm/components/PasswordInput';

import { PasswordRequirements } from '@timber-rider/parkingtree-client-common';
import { PASSWORD_REQUIREMENTS } from 'view/constants';

import { addAlert } from 'utils/components/alerts/actions';
import { ALERT_TYPES } from 'utils/components/alerts/constants';

import { Button } from '@mui/material';

import styles from 'view/private/body/resident/ChangePassword/ChangePasswordForm/index.module.css';

export class ChangePasswordForm extends Component {
  
  static propTypes = {
    addAlert: PropTypes.func.isRequired,
    text: PropTypes.shape({
      ChangePasswordForm: PropTypes.shape({
        btn_change: PropTypes.string,
        lbl_password: PropTypes.string,
        txt_title: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isPasswordValid: false,
    pass: undefined,
  };

  static contextType = SmartFormContext;

  componentDidUpdate(prevProps, prevState) {
    const inputList = this.context.inputList;
    const pass = inputList.find(input => input.inputName === 'pass');
    if (pass && pass.inputValue !== prevState.pass) {
      if (pass) this.setState({ pass: pass.inputValue });
    }
  }
  
  render() {
    const { text, } = this.props;
    const { isPasswordValid, pass } = this.state;

    return (
      <>
        <div className={styles.container}>
          <div className={styles.pageTitle}>{text.ChangePasswordForm.txt_title}</div>
          <div className={styles.password}>
            <PasswordInput
              inputName='pass'
              isRequired={true}
              labelText={text.ChangePasswordForm.lbl_password}
              style={{ width: '300px' }}
            />
          </div>
          <div className={styles.passwordRequirements}>
            <div style={{ margin: 'auto', width: '300px' }}>
              <PasswordRequirements
                password={pass || ''}
                passwordRequirements={PASSWORD_REQUIREMENTS}
                onPasswordCheck={isValid => this.setState({ isPasswordValid: isValid })}
              />
            </div>
          </div>
          <div className={styles.btns}>
            <Button
              variant='contained'
              style={{ width: '200px' }}
              disabled={!isPasswordValid}
              onClick={() => this.createPassword()}
            >
              {text.ChangePasswordForm.btn_change}
            </Button>
          </div>
        </div>
      </>
    );
  }

  createPassword() {
    this.props.addAlert(ALERT_TYPES.SUCCESS, 'You successfully changed your password.');
    this.props.history.push('/resident');
  }
}

export default withRouter(connect(null, {
  addAlert,
})(TranslationHandler(ChangePasswordForm)));