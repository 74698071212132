import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from 'utils/components/loader/index.module.css';

export class Loader extends Component {

  static propTypes = {
    isLoader: PropTypes.bool.isRequired,
  };

  render() {
    const visibility = this.props.isLoader ? 'visible' : 'hidden';
    return (
      <span className={styles.modal} style={{ visibility }}>
        <div className={styles.spinner}>
          <div className={styles.rect1} />
          <div className={styles.rect2} />
          <div className={styles.rect3} />
          <div className={styles.rect4} />
          <div className={styles.rect5} />
        </div>
      </span>
    );

  }
}

export default Loader;