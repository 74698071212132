import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker, } from '@mui/x-date-pickers/DatePicker';

import { InputAdornment } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';

export class DatePicker extends Component {

  static propTypes = {
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    maxDateMessage: PropTypes.string,
    maxDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    minDateMessage: PropTypes.string,
    minDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func,
    textFieldProps: PropTypes.object, // props that will be passed directly into TextField, if more unique changes are needed
    toolbarProps: PropTypes.object, // props that will be passed directly into calendar popup Toolbar, if more unique changes are needed
    slotProps: PropTypes.object, // props that will be passed directly into slotProps, if more unique changes are needed for other components
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    error: PropTypes.bool,
    helperText: PropTypes.string,
    readOnly: PropTypes.bool,
    inputProps: PropTypes.object,
    shouldDisableDate: PropTypes.func, // used to disable specific dates, DatePicker provides dates to check and expects back booleon response
    style: PropTypes.object,
  };

  static defaultProps = {
    textFieldProps: {},
    slotProps: {},
  };

  state = {
    error: '',
  };

  render() {
    const errorText = () => {
      const error = this.state.error || ''; // default error to blank string
      const invalidDate = 'Invalid Date';

      if (error === 'minDate') {
        return this.props.minDateMessage || invalidDate;
      }
      if (error === 'maxDate') {
        return this.props.maxDateMessage || invalidDate;
      }
      if (error === 'invalidDate' || error.includes('shouldDisable')) {
        return invalidDate;
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          {...this.props}
          slotProps={{
            ...this.props.slotProps,
            textField: {
              fullWidth: this.props.fullWidth,
              variant: this.props.variant,
              ...this.props.textFieldProps,
              error: Boolean(this.props.error || this.state.error || this.props.helperText),
              helperText: this.props.helperText || errorText(),
              readOnly: this.props.readOnly || this.props.textFieldProps.readOnly,
              inputProps: {
                ...this.props.inputProps,
              },
              InputProps: {
                endAdornment: <InputAdornment position='end'>
                  <EventIcon
                    style={{ cursor: 'pointer', }}
                    id='auto-search'
                  />
                </InputAdornment>
              }
            },
          }}
          onError={error => {
            this.setState({ error });
            this.props.onError && this.props.onError(error);
          }}
          // below are added to always ensure that value being passed in is converted to a dayjs object
          value={this.props.value ? dayjs(this.props.value) : null}
          maxDate={this.props.maxDate ? dayjs(this.props.maxDate) : null}
          minDate={this.props.minDate ? dayjs(this.props.minDate) : null}
          style={this.props.style}
        />
      </LocalizationProvider>
    );
  }
}

export default DatePicker;