import { cloneDeep } from 'lodash';

import {
  UPDATE_DETAILS,
} from './constants';

const initialState = {
  details: {},
};

function AccountDetailsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case UPDATE_DETAILS: {
      newState.details = action.payload;
      return newState;
    }

    default:
      return state;
  }
}

export default AccountDetailsReducer;