import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from '@mui/material';

import TranslationHandler from 'utils/translations/translation_handler';

import { 
  PAYMENT_TYPES,
  EXPIRATION_YEARS
} from 'view/constants';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import DropdownInput from 'utils/SmartForm/components/DropdownInput';
import CreditCardInput from 'utils/SmartForm/components/CreditCardInput';

import CVCInput from 'utils/SmartForm/components/CVCInput';
import NumericInput from 'utils/SmartForm/components/NumericInput';

import { togglePermitAdd } from 'view/actions';

import styles from 'view/private/body/guest/PaymentAdd/PaymentAddForm/index.module.css';

export class PaymentAddForm extends Component {
  
  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      PaymentAddForm: PropTypes.shape({
        btn_checkout: PropTypes.string,
        btn_goback: PropTypes.string,
        btn_card: PropTypes.string,
        btn_ach: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    paymentType: PAYMENT_TYPES.CARD,
  };

  static contextType = SmartFormContext;
  
  render() {
    const { text } = this.props;
    const paymentType = this.state.paymentType === PAYMENT_TYPES.CARD ? this.renderCC() : this.renderACH();
    return (
      <div className={styles.PymtTypeContainer}>
        <div className={styles.CCtype}>
          <Button
            variant={this.state.paymentType === PAYMENT_TYPES.CARD ? 'contained' : 'outlined'}
            style={{ width: '150px' }}
            onClick={() => this.setState({ paymentType: PAYMENT_TYPES.CARD })}
          >
            {text.PaymentAddForm.btn_card}
          </Button>
        </div>
        <div className={styles.bankAccount}>
          <Button
            variant={this.state.paymentType === PAYMENT_TYPES.ACH ? 'contained' : 'outlined'}
            style={{ width: '150px' }}
            onClick={() => this.setState({ paymentType: PAYMENT_TYPES.ACH })}
          >
            {text.PaymentAddForm.btn_ach}
          </Button>
        </div>
        <div className={styles.pymtType}>
          {paymentType}
        </div>
        <div className={styles.btns}>
          <Button
            style={{ width: '100px' }}
            onClick={() => this.props.history.goBack()}
          >
            {text.PaymentAddForm.btn_goback}
          </Button>
          <Button
            variant='contained'
            sx={{ width: '200px', fontSize: 'large', fontWeight: 'bold' }}
            onClick={e => this.checkOut(e)}
          >
            {text.PaymentAddForm.btn_checkout} $25.00
          </Button>
        </div>
      </div>
    );
  }

  renderCC() {
    return (
      <div className={styles.CCcontainer}>
        <div className={styles.ccNum}>
          <CreditCardInput
            inputName='ccNum'
            isRequired={true}
            labelText='Card Number'
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.expiration}>
          <DropdownInput
            inputName='cardYear'
            isRequired={true}
            labelText='Expiration Year'
            style={{ width: '145px', height: '75px' }}
            options={EXPIRATION_YEARS.map(year => ({
              value: year.id,
              display: year.name,
            }))}
          />
        </div>
        <div className={styles.cvc}>
          <CVCInput
            inputName='CVC'
            isRequired={true}
            labelText='CVC'
            style={{ width: '145px', height: '75px' }}
          />
        </div>
      </div>
    );
  }

  renderACH() {
    return (
      <div className={styles.ACHcontainer}>
        <div className={styles.routingNumb}>
          <NumericInput
            inputName='routingNumber'
            isRequired={true}
            labelText='Routing Number'
            style={{ width: '300px', height: '75px' }}
            fieldMaxLength={10}
          />
        </div>
        <div className={styles.acctNum}>
          <NumericInput
            inputName='acctNumber'
            isRequired={true}
            labelText='Account Number'
            style={{ width: '300px', height: '75px' }}
            fieldMaxLength={15}
          />
        </div>
      </div>
    );
  }

  checkOut(e) {
    e.preventDefault();
    // const validObj = this.context.checkRequiredInputs(this.context.inputList);
    // if (validObj.isValid) {
    //   // const formattedObject = this.context.prepInputList(validObj.inputList);
    //   this.props.togglePermitAdd(false);
    //   this.props.history.push('/guest');
    // }
    // else {
    //   this.context.updateInputList(validObj.inputList);
    // }
    this.props.togglePermitAdd(false);
    this.props.history.push('/guest');
  }
}

export default withRouter(connect(null, {
  togglePermitAdd,
})(TranslationHandler(PaymentAddForm)));