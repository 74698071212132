import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import dayjs from 'dayjs';

import DatePicker from 'utils/components/DatePicker';

export class DatePickerInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,

    // minDate: PropTypes.string,
    minDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    maxDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    openToYear: PropTypes.bool,
  };

  state = {
    val: null,
    inputIndex: undefined,
    isError: undefined,
    helperMsg: ''
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const inputValue = this.context.inputList[this.state.inputIndex].inputValue;
    if (prevState.inputValue !== inputValue) {
      this.setState({ inputValue });
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const { 
      inputName, labelText, isDisabled, isReadOnly,
      inputFullWidth, className, style, inputFocus,
      minDate, maxDate, openToYear,
    } = this.props;

    const { inputValue, isError, helperMsg } = this.state;
    
    return (
      <DatePicker
        key={inputName}
        error={isError || this.context.helperErrorManage(inputName)}
        helperText={helperMsg || this.context.helperTextManage(inputName)}
        readOnly={isReadOnly} // disables calendar date picker
        disabled={isDisabled}
        fullWidth={inputFullWidth}
        autoFocus={inputFocus || false}
        label={labelText}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        onChange={date => this.inputChange({ target: { value: date }, type: 'change' })}
        value={inputValue || null}
        openTo={openToYear} // regardless of what string is put in, it opens to year, otherwise opens on current month.
        className={className}
        style={style}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        variant='outlined'
      />
    );
  }

  inputChange = e => {
    const inputValue = e.target.value || '';

    const { inputName, minDate, maxDate, isRequired } = this.props;
    let helperMsg = '';
    let isError = undefined;
    const min = dayjs(minDate).format('MM/DD/YYYY');
    const max = dayjs(maxDate).format('MM/DD/YYYY');

    if (!dayjs(inputValue).isValid() && isRequired) {
      isError = true;
      helperMsg = 'Please enter a valid Date';
    }
    else if (minDate && maxDate && (dayjs(inputValue).isAfter(dayjs(maxDate), 'day') || dayjs(inputValue).isBefore(dayjs(minDate), 'day'))) {
      isError = true;
      helperMsg = `Date must be between ${min} and ${max}.`;
    }
    else if (minDate && !maxDate && dayjs(inputValue).isBefore(dayjs(minDate), 'day')) {
      isError = true;
      helperMsg = `Date must be on or after ${min}`;
    }
    else if (maxDate && !minDate && dayjs(inputValue).isAfter(dayjs(maxDate), 'day')) {
      isError = true;
      helperMsg = `Date must be on or before ${max}`;
    }
    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  };
  
}

export default DatePickerInput;