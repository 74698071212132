export const ADDRESS_TYPE = {
  MAILING: 'mailing',
  PHYSICAL: 'physical',
  OTHER: 'other'
};

export const EVENT_REASON_TYPE = {
  UPDATE: 'update',
  RESET: 'reset',
  CLEAR: 'clear',
  INPUT: 'input',
  CHANGE: 'change',
};

export const CC_TYPES = {
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  AMEX: 'AMEX'
};

export const REQUIRED = 'Field is required';

export const cleanDigits = (str) => str.replace(/[^\d]+/g, ''); // remove all non-digit characters
export const sanitizeHTML = (str) => str.replace(/[&"'<>\\`:]/g, ''); // remove all dangerous tags
export const alphaNumericOnly = (str) => str.replace(/[^0-9a-zA-Z]/g, '');