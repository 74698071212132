import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import Header from 'view/public/Header';

import EmailInput from 'utils/SmartForm/components/EmailInput';
import PasswordInput from 'utils/SmartForm/components/PasswordInput';

import {
  toggleLoaderOn,
  toggleLoaderOff,
  userLogin,
} from 'view/actions';

import styles from 'view/public/Login/index.module.css';

const select = state => ({
  isAuthenticated: state.session.language,
});

export class LoginForm extends Component {

  static propTypes = {
    userLogin: PropTypes.func.isRequired,
    toggleLoaderOn: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Login: PropTypes.shape({
        btn_login: PropTypes.string,
        btn_signup: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_password: PropTypes.string,
        txt_forgot_password: PropTypes.string,
        txt_forgot_email: PropTypes.string,
        txt_no_account: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
  };

  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;
    return (
      <form 
        onSubmit={this.loginUser}
        className={styles.container}
      > 
        <div className={styles.container}>
          <div className={styles.header}><Header /></div>
          <div className={styles.logo}>
            <img src={ require('utils/media/logo.png') } alt='The Parking Tree' width='325px' />
          </div>
          <div className={styles.email}>
            <EmailInput
              inputName='email'
              isRequired={true}
              labelText={text.Login.lbl_email}
              style={{ width: '240px', height: '75px' }}
              inputFocus={true}
            />
          </div>
          <div className={styles.pass}>
            <PasswordInput
              inputName='password'
              isRequired={true}
              labelText={text.Login.lbl_password}
              style={{ width: '240px', height: '75px' }}
            />
          </div>
          <div className={styles.btn_login}>
            <Button
              type='submit'
              variant='contained'
              style={{ width: '250px', marginBottom: '10px' }}
              onClick={e => this.loginUser(e)}
            >
              {text.Login.btn_login}
            </Button>
          </div>
          <div className={styles.links}>
            <Button
              style={{ width: '300px', marginBottom: '10px' }}
              onClick={() => this.props.history.push('/forgot-password')}
            >
              {text.Login.txt_forgot_password}
            </Button><br />
            <Button
              style={{ width: '300px', marginBottom: '10px' }}
              onClick={() => this.props.history.push('/forgot-email')}
            >
              {text.Login.txt_forgot_email}
            </Button><br />
            {text.Login.txt_no_account}<br />
            <Button
              style={{ width: '300px' }}
              onClick={() => this.props.history.push('/search-property')}
            >
              {text.Login.btn_signup}
            </Button><p />
          </div>
        </div>
      </form>
    );
  }

  loginUser(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedList = this.context.prepInputList(validObj.inputList);
      this.props.toggleLoaderOn();
      this.props.userLogin(formattedList);
      this.props.toggleLoaderOff();
      this.props.history.push('/resident');
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }
  
}

export default withRouter(connect(select, { 
  userLogin,
  toggleLoaderOn,
  toggleLoaderOff,
})(TranslationHandler(LoginForm)));