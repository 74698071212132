import React from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import { EVENT_REASON_TYPE } from 'utils/SmartForm/constants';

import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

export class StateInput extends React.Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  state = {
    val: '',
    inputIndex: undefined,
    inputValue: '',
    isError: undefined,
    helperMsg: ''
  };

  static contextType = SmartFormContext;
  
  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const currentInput = this.context.inputList[this.state.inputIndex];
    if (prevState.inputValue !== currentInput.inputValue && currentInput.eventType !== EVENT_REASON_TYPE.CHANGE) {
      this.handleEntry(null, this.getStateByCode(currentInput.inputValue), EVENT_REASON_TYPE.UPDATE);
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const { inputName, labelText, isDisabled, isReadOnly, style } = this.props;
    const { inputValue, isError, helperMsg } = this.state;
    return (
      <Autocomplete
        options={USStates}
        getOptionLabel={usState => usState.Name}
        onChange={(e, obj) => this.handleSelect(e, obj)}
        onInputChange={(e, inputValue, reason) => this.handleEntry(e, inputValue, reason)}
        onBlur={() => this.handleNoMatch()}
        inputValue={inputValue || ''}
        disabled={isDisabled || isReadOnly}
        renderInput={params => (
          <TextField
            {...params}
            name={inputName}
            disabled={isDisabled} // need this here to overwrite disable from Autocomplete params that are passed
            label={labelText}
            helperText={helperMsg || this.context.helperTextManage(inputName)}
            error={isError || this.context.helperErrorManage(inputName)}
            style={style}
            InputProps={{
              ...params.InputProps,
              readOnly: isReadOnly,
            }}
            variant='outlined'
          />
        )}
        key={inputName}
        data-testid={inputName}
      />
    );
  }

  getStateByCode = code => {
    const specificState = USStates.find(usState => usState.Code === code);
    return specificState === undefined ? { Code: '', Name: '' } : specificState;
  };

  getStateByStateName = stateName => {
    const specificState = USStates.find(usState => usState.Name === stateName);
    return specificState === undefined ? { Code: '', Name: '' } : specificState;
  };

  handleSelect(e, obj) {
    const { inputName } = this.props;
    let inputValue;
    if (obj) {
      inputValue = obj.Code;
      this.context.updateInput(this.context.inputList, inputName, inputValue);
    }
    else {
      this.context.updateInput(this.context.inputList, inputName, '');
    }
  }

  handleEntry(e, inputValue, reason) {
    const { inputName } = this.props;
    const eventType = e && e.type ? e.type : null; // detect if event is user or programmatic driven
  
    if (reason === EVENT_REASON_TYPE.UPDATE) {
      // do nothing
    }
    if (reason === EVENT_REASON_TYPE.RESET && inputValue !== this.state.inputValue && inputValue !== '') {
      this.setState({ inputValue });
    }
    else if (reason === EVENT_REASON_TYPE.CLEAR && inputValue === '') {
      this.setState(
        { inputValue: '' },
        this.context.updateInput(this.context.inputList, inputName, '', eventType)
      );
    }
    else if (reason === EVENT_REASON_TYPE.INPUT) {      
      this.setState(
        { inputValue },
        this.context.updateInput(this.context.inputList, inputName, '', eventType)
      );
    }
  }

  handleNoMatch() {
    let isMatch = false;
    let inputValue = undefined;
    const { inputName } = this.props;
  
    USStates.forEach(usState => {
      if (usState.Name === this.state.inputValue) {
        isMatch = true;
        inputValue = usState.Code;
      }
    });

    if (isMatch) {
      this.setState(
        { inputValue: this.state.inputValue },
        this.context.updateInput(this.context.inputList, inputName, inputValue)
      );
    }
    else {
      this.setState({ inputValue: '' });
    }
  }

}

export default StateInput;

const USStates = [
  { Code: 'AL', Name: 'Alabama' },
  { Code: 'AK', Name: 'Alaska' },
  { Code: 'AZ', Name: 'Arizona' },
  { Code: 'AR', Name: 'Arkansas' },
  { Code: 'CA', Name: 'California' },
  { Code: 'CO', Name: 'Colorado' },
  { Code: 'CT', Name: 'Connecticut' },
  { Code: 'DE', Name: 'Delaware' },
  { Code: 'DC', Name: 'District Of Columbia' },
  { Code: 'FL', Name: 'Florida' },
  { Code: 'GA', Name: 'Georgia' },
  { Code: 'GU', Name: 'Guam' },
  { Code: 'HI', Name: 'Hawaii' },
  { Code: 'ID', Name: 'Idaho' },
  { Code: 'IL', Name: 'Illinois' },
  { Code: 'IN', Name: 'Indiana' },
  { Code: 'IA', Name: 'Iowa' },
  { Code: 'KS', Name: 'Kansas' },
  { Code: 'KY', Name: 'Kentucky' },
  { Code: 'LA', Name: 'Louisiana' },
  { Code: 'ME', Name: 'Maine' },
  { Code: 'MD', Name: 'Maryland' },
  { Code: 'MA', Name: 'Massachusetts' },
  { Code: 'MI', Name: 'Michigan' },
  { Code: 'MN', Name: 'Minnesota' },
  { Code: 'MS', Name: 'Mississippi' },
  { Code: 'MO', Name: 'Missouri' },
  { Code: 'MT', Name: 'Montana' },
  { Code: 'NE', Name: 'Nebraska' },
  { Code: 'NV', Name: 'Nevada' },
  { Code: 'NH', Name: 'New Hampshire' },
  { Code: 'NJ', Name: 'New Jersey' },
  { Code: 'NM', Name: 'New Mexico' },
  { Code: 'NY', Name: 'New York' },
  { Code: 'NC', Name: 'North Carolina' },
  { Code: 'ND', Name: 'North Dakota' },
  { Code: 'OH', Name: 'Ohio' },
  { Code: 'OK', Name: 'Oklahoma' },
  { Code: 'OR', Name: 'Oregon' },
  { Code: 'PA', Name: 'Pennsylvania' },
  { Code: 'RI', Name: 'Rhode Island' },
  { Code: 'SC', Name: 'South Carolina' },
  { Code: 'SD', Name: 'South Dakota' },
  { Code: 'TN', Name: 'Tennessee' },
  { Code: 'TX', Name: 'Texas' },
  { Code: 'UT', Name: 'Utah' },
  { Code: 'VT', Name: 'Vermont' },
  { Code: 'VI', Name: 'Virgin Islands' },
  { Code: 'VA', Name: 'Virginia' },
  { Code: 'WA', Name: 'Washington' },
  { Code: 'WV', Name: 'West Virginia' },
  { Code: 'WI', Name: 'Wisconsin' },
  { Code: 'WY', Name: 'Wyoming' }
];