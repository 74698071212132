import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TranslationHandler from 'utils/translations/translation_handler';

import { Button } from '@mui/material';

import styles from 'view/private/body/guest/AccountInfo/index.module.css';

export class AccountInfo extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      AccountInfo: PropTypes.shape({
        btn_edit_info:  PropTypes.string,
        btn_change_pass:  PropTypes.string,
        txt_account_info: PropTypes.string,
        btn_billing_info: PropTypes.string,

      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.AccountInfo.txt_account_info}</div>
        <div className={styles.acctInfo}>
          <div><strong>Tito Ruan</strong></div>
          <div>tito@gmail.com</div>
          <div>(801) 663-2196)</div>
        </div>
        <div className={styles.btns}>
          <Button
            variant='outlined'
            onClick={() => this.props.history.push('/guest/account-info-edit/123')}
          >
            {text.AccountInfo.btn_edit_info}
          </Button><br /><br />
          <Button
            variant='outlined'
            onClick={() => this.props.history.push('/guest/billing-info')}
          >
            {text.AccountInfo.btn_billing_info}
          </Button><br /><br />
          <Button
            variant='outlined'
            onClick={() => this.props.history.push('/guest/send-otp')}
          >
            {text.AccountInfo.btn_change_pass}
          </Button>
        </div>
      </div>
    );
  }
}

export default TranslationHandler(AccountInfo);