import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TranslationHandler from 'utils/translations/translation_handler';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import AccountInfoEditForm from 'view/private/body/resident/AccountInfoEdit/AccountInfoEditForm';

import styles from 'view/private/body/resident/AccountInfoEdit/index.module.css';

export class AccountInfoEdit extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      AccountInfoEdit: PropTypes.shape({
        txt_edit_account: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.AccountInfoEdit.txt_edit_account}</div>
        <div className={styles.pageBody}>
          <SmartFormProvider>
            <AccountInfoEditForm />
          </SmartFormProvider>
        </div>
      </div>
    );
  }
}

export default TranslationHandler(AccountInfoEdit);