import { buttons } from 'utils/translations/common';

const English = {
  AccountCreateConfirmation: {
    btn_create_account: 'create account',
    btn_goback: buttons.goback,
    lbl_account_type: 'account type',
    lbl_name: 'name:',
    lbl_email: 'email',
    lbl_login: 'Login',
    lbl_mobile: 'mobile',
    lbl_unit: 'unit',
    txt_haveacct: 'Already have an account?',
    txt_title: 'Account Creation Confirmation',
  },
  AccountDetails: {
    btn_add_details: 'add details',
    btn_goback: buttons.goback,
    lbl_email: 'email',
    lbl_first_name: 'first name',
    lbl_last_name: 'last name',
    lbl_login: 'Login',
    lbl_mobile: 'mobile',
    lbl_select_unit: 'select your unit number',
    txt_create_account: 'Account Details',
    txt_haveacct: 'Already have an account?',
    txt_sms_disclaimer: 'You will receive SMS notifications only about matters relating to the status of your parking permits.',
  },
  AccountInfo: {
    btn_edit_info: 'edit account information',
    btn_billing_info: 'billing information',
    btn_change_pass: 'change password',
    txt_account_info: 'Account Info',
  },
  BillingInfo: {
    txt_title: 'Billing Information',
  },
  AccountInfoEdit: {
    txt_edit_account: 'Edit Account Info'
  },
  AccountInfoEditForm: {
    btn_cancel: buttons.cancel,
    btn_update: buttons.update,
    lbl_first_name: 'First Name',
    lbl_last_name: 'Last Name',
    lbl_phone: 'Phone',
    lbl_email: 'Email',
    lbl_address: 'Address',
    lbl_city: 'City',
    lbl_state: 'State',
    lbl_zip: 'Zip Code',
  },
  ChangePasswordForm: {
    btn_change: 'change password',
    lbl_password: 'new password',
    txt_title: 'Change Account Password',
  },
  Contact: {

  },
  ContactForm: {

  },
  ContactInfo: {
    txt_contact_us: 'How can you contact us?',
    txt_dilbert: 'Use this form Dilbert!',
  },
  ContactInfoForm: {
    btn_send: buttons.send,
    btn_cancel: buttons.cancel,
    lbl_name: 'Name',
    lbl_email: 'Email',
    lbl_phone: 'Phone',
    lbl_property: 'Property',
    lbl_message: 'Message',
    txt_get_in_contact: 'Get in contact with our support team:',
  },
  CreatePassword: {
    btn_create: buttons.create,
    btn_goback: buttons.goback,
    lbl_login: 'Login',
    lbl_password: 'new password',
    txt_title: 'Create Account Password',
    txt_haveacct: 'Already have an account?',
  },
  Documents: {
    btn_addpermit: 'add permit',
    txt_community: 'Community Documents',
  },
  ForgotEmail: {
    btn_cancel: buttons.cancel,
    btn_submit: buttons.submit,
    lbl_email: 'email',
    txt_title: 'forgot email',
    txt_confirm_email: 'We will confirm if your email address is associated with an account.',
  },
  ForgotPassword: {
    btn_cancel: buttons.cancel,
    btn_send: buttons.send,
    lbl_email: 'email',
    txt_title: 'forgot password',
    txt_confirm: 'If you do not receive a text within 10 minutes, please contact support.',
    txt_link: 'We will email you a link and a One Time Password so you can reset your password.',
  },
  Login: {
    btn_login: 'login',
    btn_signup: 'sign up',
    lbl_email: 'email',
    lbl_password: 'password',
    txt_forgot_password: 'forgot password',
    txt_forgot_email: 'forgot email',
    txt_no_account: 'Don\'t have an account?',
  },
  OneTimePassword: {
    btn_submit: buttons.submit,
    lbl_otp: 'one time password',
    txt_explanation: 'Please provide the One Time Password we emailed you. This password is valid for a limited period of time',
    txt_title: 'One Time Password',
  },
  PageNotFound: {
    txt_cant_find: 'We can\'t seem to find the page you\'re looking for?',
    txt_start_over: 'Start Over!',
  },
  PublicHeader: {
    btn_help: 'get help',
  },
  PaymentAdd: {
    txt_payment_details: 'Permit Details',
    txt_total: 'Total',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    lbl_confirm_exit: 'exit without saving',
    txt_are_sure: 'Are you sure you want to cancel your permit registration? If you cancel now, you will not receive a permit.'

  },
  PaymentAddForm: {
    btn_checkout: 'Checkout ',
    btn_goback: buttons.goback,
    btn_card: 'card',
    btn_ach: 'ach',
  },
  PermitSelect: {
    btn_change: buttons.change,
    txt_choose_permit: 'Choose A Resident Permit',
    txt_your_community: 'Your Community',
    txt_guest_permit: 'Need a guest permit?',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    lbl_confirm_exit: 'exit without saving',
    txt_are_sure: 'Are you sure you want to cancel your permit registration? If you cancel now, you will not receive a permit.'

  },
  ResetPassword: {
    btn_reset: 'reset password',
    lbl_password: 'new password',
    txt_message: 'Once you provide a new password you will be logged into the app.',
    txt_title: 'Reset Password',
  },
  ResidentHome: {
    btn_add_permit: 'add new permit',
    btn_cancel: buttons.cancel,
    btn_cancel_permit: 'cancel permit',
    btn_edit_vehicle: 'edit vehicle',
    btn_permit_vehicle: 'permit this vehicle',
    btn_ok: buttons.ok,
    btn_remove_vehicle: 'remove vehicle',
    lbl_confirm_delete: 'confirm delete',
    lbl_expiration_date: 'Expiration Date:',
    lbl_expiring_soon: 'Expiring Soon',
    lbl_license_plate: 'License Plate:',
    lbl_next_payment: 'Next Payment:',
    lbl_permit_id: 'Permit ID:',
    lbl_permit_type: 'Permit Type:',
    lbl_property: 'Property:',
    lbl_start_date: 'Start Date:',
    lbl_unit: 'Unit:',
    lbl_vin: 'VIN:',
    txt_active: 'active',
    txt_are_sure: 'Are you sure you want to cancel this Permit?',
    txt_permitted_vehicles: 'Permitted Vehicle(s)',
    txt_not_permitted: 'not permitted',
    txt_unpermitted_vehicles: 'Unpermitted Vehicle(s)',
    txt_delete_vehicle: 'Are you sure you want to remove this vehicle?',
  },
  SendOTP: {
    btn_send_otp: 'send a one time password',
    lbl_email: 'email address',
    txt_page_title: 'Send One Time Password',
    txt_explanation: 'In order to change your password we first need to confirm your email address. When you press the send button below we will email a One Time Password to your email address.',
    txt_warning: 'NOTE: Your One Time Password will expire in 10 minutes.',
  },
  SessionMenu: {
    lbl_documents: 'documents',
    lbl_billing_info: 'billing info',
    lbl_account_info: 'account info',
    lbl_logout: 'logout',
  },
  SearchProperty: {
    btn_search: buttons.search,
    lbl_login: 'Login',
    lbl_search: 'Search for your community',
    txt_find: 'Find Your Community',
    txt_haveacct: 'Already have an account?',
    txt_search: 'Search by community name:',
  },
  UserTypeSelection: {
    btn_resident: 'resident',
    btn_guest: 'guest',
    btn_goback: buttons.goback,
    lbl_login: 'Login',
    txt_your_community: 'Your Community',
    txt_haveacct: 'Already have an account?',
  },
  ValidateEmail: {
    btn_goback: buttons.goback,
    btn_send: buttons.send,
    lbl_email: 'email',
    txt_explanation: 'Please provide your email address. We will email a One Time Password to you.',
    txt_title: 'Validate Your Email',
  },
  VehicleAdd: {
    btn_goback: buttons.goback,
    btn_next: buttons.next,
    lbl_choose_vehicle: 'Choose Existing Vehicle',
    txt_or: 'OR',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    lbl_confirm_exit: 'exit without saving',
    txt_are_sure: 'Are you sure you want to cancel your permit registration? If you cancel now, you will not receive a permit.'

  },
  VehicleAddForm: {
    btn_next: buttons.next,
    btn_goback: buttons.goback,
    lbl_color: 'Color',
    lbl_license_plate: 'License Plate',
    lbl_make: 'Make',
    lbl_model: 'Model',
    lbl_state: 'State',
    lbl_vin: 'Last 6 digits of VIN',
    lbl_year: 'Year',
    txt_notice: 'Your license plate number and your VIN number will be used to verify that you have a parking permit. Be sure to enter your information correctly or you may be towed, ticketed, or booted.',
    txt_vehicle_info: 'Vehicle Information',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    lbl_confirm_exit: 'exit without saving',
    txt_are_sure: 'Are you sure you want to cancel your permit registration? If you cancel now, you will not receive a permit.'

  },
  VehicleEdit: {
    txt_edit_vehicle: 'Edit Vehicle',
    txt_are_sure: 'Are you sure you don’t want to edit or change your vehicle? If you cancel now, your permitted vehicle will remain the same.',
    btn_ok: buttons.ok,
    btn_cancel: buttons.cancel,
    lbl_confirm_exit: 'exit without saving',
    txt_change_vehicle: 'Change to a different vehicle',
  },
  VehicleEditForm: {
    btn_next: 'save',
    btn_goback: buttons.goback,
    lbl_color: 'Color',
    lbl_license_plate: 'License Plate',
    lbl_make: 'Make',
    lbl_model: 'Model',
    lbl_state: 'State',
    lbl_vin: 'Last 6 digits of VIN',
    lbl_year: 'Year',
    txt_notice: 'Your license plate number and your VIN number will be used to verify that you have a parking permit. Be sure to enter your information correctly or you may be towed, ticketed, or booted.',
    txt_vehicle_info: 'Edit Existing Vehicle Info',
    txt_or: 'OR'
  },
  VehicleChange: {
    btn_next: 'save',
    btn_goback: buttons.goback,
    lbl_choose_vehicle: 'Change to an existing vehicle',
    txt_or: 'OR',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    lbl_confirm_exit: 'exit without changing',
    txt_are_sure: 'Are you sure you don’t want to edit or change your vehicle? If you cancel now, your permitted vehicle will remain the same.'
  },
  VehicleChangeForm: {
    btn_next: 'save',
    btn_goback: buttons.goback,
    lbl_color: 'Color',
    lbl_license_plate: 'License Plate',
    lbl_make: 'Make',
    lbl_model: 'Model',
    lbl_state: 'State',
    lbl_vin: 'Last 6 digits of VIN',
    lbl_year: 'Year',
    txt_notice: 'Your license plate number and your VIN number will be used to verify that you have a parking permit. Be sure to enter your information correctly or you may be towed, ticketed, or booted.',
    txt_vehicle_info: 'Add New Vehicle',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    lbl_confirm_exit: 'exit without changing',
    txt_are_sure: 'Are you sure you don’t want to edit or change your vehicle? If you cancel now, your permitted vehicle will remain the same.'
  },
  ValidateOTP: {
    btn_submit: buttons.submit,
    lbl_otp: 'one time password',
    txt_explanation: 'Please provide the One Time Password we emailed you. This password is valid for a limited period of time',
    txt_title: 'One Time Password',
  },
  GuestPermitSelect: {
    txt_your_community: 'Your Community',
    txt_guest_permit: 'Choose a Guest Permit',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    lbl_confirm_exit: 'exit without saving',
    txt_need_resident_permit: 'Are you a resident?',
    txt_are_sure: 'Are you sure you want to cancel your permit registration? If you cancel now, you will not receive a permit.'
  },
  GuestPermitInfo: {
    btn_next: buttons.next,
    btn_goback: buttons.goback,
    txt_title: 'Permit Information',
    lbl_unit: 'Unit number you’re visiting',
    txt_permit_question: 'How long do you need the permit?',
    lbl_hour: 'Hours',
    lbl_day: 'Days',
    lbl_day_visit: 'Days you’re visiting',
    lbl_hour_visit: 'Hours you’re visiting',
  },
  ResidentGuestPermitInfo: {
    btn_next: buttons.next,
    btn_goback: buttons.goback,
    txt_title: 'Permit Information',
    txt_permit_question: 'How long is the permit needed?',
    lbl_hour: 'Hours',
    lbl_day: 'Days',
    lbl_day_visit: 'Select how many days',
    lbl_hour_visit: 'Select how many hours',
  },
  Notification: {
    txt_title: 'Permit Notifications & History',
    lbl_permit_id: 'Permit ID:',
    lbl_permit_space: 'Assigned Covered Permit Space #',
    lbl_apartment: 'Apartment:',
    lbl_unit: 'Unit:',
    lbl_make: 'Make:',
    lbl_Model: 'Model:',
    lbl_plate: 'License Plate #',
    lbl_vin: 'VIN:',
  },
  SelectUnit: {
    btn_next: buttons.next,
    btn_goback: buttons.goback,
    txt_title: 'Select the unit number you reside in:',
    lbl_unit: 'Select your unit number',
    btn_cancel: buttons.cancel,
    btn_ok: 'Confirm',
    lbl_confirm_exit: 'Please confirm',
    txt_are_sure: 'Please confirm that you are a resident.'
  },
};

export default English;