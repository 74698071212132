import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TranslationHandler from 'utils/translations/translation_handler';

import styles from 'view/private/body/resident/BillingInfo/index.module.css';

export class AccountInfoEdit extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      BillingInfo: PropTypes.shape({
        txt_title: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.BillingInfo.txt_title}</div>
      </div>
    );
  }
}

export default TranslationHandler(AccountInfoEdit);