import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import Header from 'view/public/Header';

import { authenticateAccount, togglePermitAdd } from 'view/actions';

import PasswordInput from 'utils/SmartForm/components/PasswordInput';

import { PasswordRequirements } from '@timber-rider/parkingtree-client-common';
import { PASSWORD_REQUIREMENTS } from 'view/constants';

import { Button } from '@mui/material';

import styles from 'view/public/CreateAccount/CreatePassword/CreatePasswordForm/index.module.css';

const select = state => ({
  isResident: state.session.isResident,
});

export class CreatePasswordForm extends Component {
  
  static propTypes = {
    authenticateAccount: PropTypes.func.isRequired,
    togglePermitAdd: PropTypes.func.isRequired,
    isResident: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      CreatePassword: PropTypes.shape({
        btn_create: PropTypes.string,
        btn_goback: PropTypes.string,
        lbl_password: PropTypes.string,
        lbl_login: PropTypes.string,
        txt_title: PropTypes.string,
        txt_haveacct: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isPasswordValid: false,
    pass: undefined,
  };

  static contextType = SmartFormContext;

  componentDidUpdate(prevProps, prevState) {
    const inputList = this.context.inputList;
    const pass = inputList.find(input => input.inputName === 'pass');
    if (pass && pass.inputValue !== prevState.pass) {
      if (pass) this.setState({ pass: pass.inputValue });
    }
  }
  
  render() {
    const { text, } = this.props;
    const { isPasswordValid, pass } = this.state;

    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}><Header /></div>
          <div className={styles.pageTitle}>{text.CreatePassword.txt_title}</div>
          <div className={styles.password}>
            <PasswordInput
              inputName='pass'
              isRequired={true}
              labelText={text.CreatePassword.lbl_password}
              style={{ width: '300px' }}
            />
          </div>
          <div className={styles.passwordRequirements}>
            <div style={{ margin: 'auto', width: '300px' }}>
              <PasswordRequirements
                password={pass || ''}
                passwordRequirements={PASSWORD_REQUIREMENTS}
                onPasswordCheck={isValid => this.setState({ isPasswordValid: isValid })}
              />
            </div>
          </div>
          <div className={styles.btns}>
            <Button
              variant='contained'
              style={{ width: '200px' }}
              disabled={!isPasswordValid}
              onClick={() => this.createPassword()}
            >
              {text.CreatePassword.btn_create}
            </Button>
          </div>
        </div>
      </>
    );
  }

  createPassword() {
    this.props.authenticateAccount();
    this.props.togglePermitAdd(true);
    const isResident = cloneDeep(this.props.isResident);
    if (isResident == true) {
      this.props.history.push('/resident/permit-select');
    } 
    else {
      this.props.history.push('/guest/permit-select');
    }
  }
}

export default withRouter(connect(select, {
  authenticateAccount,
  togglePermitAdd,
})(TranslationHandler(CreatePasswordForm)));