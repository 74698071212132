// import { POSTrequest } from 'utils/helpers/api_handler';
import {
  SET_USER_TYPE,
  INITIALIZE_STORE,
  LOGIN,
  AUTHENTICATE_ACCOUNT,
  BROWSER_WARNING,
  MOBILE_WARNING,
  LANGUAGE_CHANGE,
  TOGGLE_PERMIT_ADD,
  TOGGLE_LOADER_ON,
  TOGGLE_LOADER_OFF,
} from 'view/constants';
  
/////////////////////////////// AUTHENTICATION //////////////////////////////////
export function userLogin(body) {
  // const response = POSTrequest('/authentication/login', body);
  return {
    type: LOGIN,
    payload: body
  };
}

export function authenticateAccount(body) {
  // const response = POSTrequest('/authentication/login', body);
  return {
    type: AUTHENTICATE_ACCOUNT,
    payload: body
  };
}

export function updateUserType(body) {
  return {
    type: SET_USER_TYPE,
    payload: body
  };
}

export function userLogout() {
  // const response = POSTrequest('/authentication/logout', body);
  return {
    type: INITIALIZE_STORE,
  };
}

export function changeLanguage(lang) {
  return {
    type: LANGUAGE_CHANGE,
    payload: lang,
  };
}

export function setBrowserWarning() {
  return {
    type: BROWSER_WARNING,
  };
}

export function setMobileWarning() {
  return {
    type: MOBILE_WARNING,
  };
}

export function clearStore() {
  return {
    type: INITIALIZE_STORE
  };
}

export function togglePermitAdd(isAddingPermit) {
  return {
    type: TOGGLE_PERMIT_ADD,
    payload: isAddingPermit,
  };
}

export function toggleLoaderOn() {
  return {
    type: TOGGLE_LOADER_ON,
  };
}

export function toggleLoaderOff() {
  return {
    type: TOGGLE_LOADER_OFF,
  };
}