import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { TextField } from '@mui/material';

export class LicensePlateInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,
  };

  state = {
    inputValue: '',
    inputIndex: undefined,
    isError: undefined,
    helperMsg: '',
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const inputValue = this.context.inputList[this.state.inputIndex].inputValue;
    if (prevState.inputValue !== inputValue) {
      this.setState({ inputValue });
    }
  }
  
  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const {
      inputName, labelText, isDisabled, isReadOnly, inputFocus,
      inputFullWidth, autoComplete, className, style,
    } = this.props;

    const { inputValue, isError, helperMsg } = this.state;

    return (
      <TextField
        name={inputName}
        value={inputValue || ''}
        label={labelText}
        disabled={isDisabled}
        helperText={helperMsg || this.context.helperTextManage(inputName)}
        error={isError || this.context.helperErrorManage(inputName)}
        autoFocus={inputFocus || false}
        fullWidth={inputFullWidth || false}
        autoComplete={autoComplete || 'on'}
        style={style}
        className={className}
        onChange={e => this.inputChange(e)}
        variant='outlined'
        inputProps={{
          maxLength: 8,
        }}
        InputProps={{
          readOnly: isReadOnly,
        }}
        key={inputName}
        data-testid={inputName}
      />
    );
  }

  inputChange = e => {
    let inputValue = e.target.value?.trimStart() || '';

    const { inputName } = this.props;
    let helperMsg = '';
    let isError = undefined;
    
    const regex = /^[^+_=/*?@#$%&()'"|â„;:{}.,`~<>}{][^\\]{1,20}$/g; // case insensitive, space, period, comma, single quote and hyphen.

    if (inputValue.length === 0) {
      inputValue = undefined;
    }
    else if (inputValue.length > 0 && !regex.test(inputValue)) {
      helperMsg = "Please enter valide license plate"; // eslint-disable-line
      isError = true;
    }
    else {
      helperMsg = '';
      isError = undefined;
    }

    if (inputValue && inputValue.length > 0) {
      inputValue = inputValue.toUpperCase();
    }

    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  };

}

export default LicensePlateInput;