import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import Header from 'view/public/Header';

import { Button } from '@mui/material';

import EmailInput from 'utils/SmartForm/components/EmailInput';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

import styles from 'view/public/ForgotEmail/ForgotEmailForm/index.module.css';

export class ForgotEmailForm extends Component {

  static propTypes = {
    text: PropTypes.shape({
      ForgotEmail: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_submit: PropTypes.string,
        lbl_email: PropTypes.string,
        txt_title: PropTypes.string,
        txt_confirm_email: PropTypes.string,
      }),
    }).isRequired,
  };
  
  state = {
    isLoading: false,
  };

  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;
    return (
      <div className={styles.formContainer}>
        <LoadingOverlay
          show={this.state.isLoading}
          width='100%'
        > 
          <form onSubmit={this.forgotEmailSubmit}>
            <div className={styles.container}>
              <div className={styles.logo}>
                <Header />
              </div>
              <div className={styles.title}><p />{text.ForgotEmail.txt_title}</div>
              <div className={styles.explanation}>
                <p>{text.ForgotEmail.txt_confirm_email}</p>
              </div>
              <div className={styles.email}>
                <EmailInput
                  inputName='email'
                  isRequired={true}
                  labelText={text.ForgotEmail.lbl_email}
                  style={{ width: '300px' }}
                  autoComplete={'off'}
                  inputFocus={true}
                />
              </div>
              <div className={styles.btn}>
                <Button
                  type='submit'
                  style={{ width: '150px' }}
                  onClick={() => this.props.history.push('/')}
                >
                  {text.ForgotEmail.btn_cancel}
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  style={{ width: '150px' }}
                  onClick={e => this.forgotEmailSubmit(e)}
                >
                  {text.ForgotEmail.btn_submit}
                </Button>
              </div>
            </div>
          </form>              
        </LoadingOverlay>
      </div>
    );
  }

  forgotEmailSubmit(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedList = this.context.prepInputList(validObj.inputList);
      alert(JSON.stringify(formattedList));
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }

}

export default withRouter(connect(null, {})(TranslationHandler(ForgotEmailForm)));