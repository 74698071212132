import { cloneDeep } from 'lodash';
import {
  SET_USER_TYPE,
  LOGIN,
  AUTHENTICATE_ACCOUNT,
  BROWSER_WARNING,
  MOBILE_WARNING,
  LANGUAGE_CHANGE,
  LANGUAGES,
  TOGGLE_PERMIT_ADD,
  TOGGLE_LOADER_ON,
  TOGGLE_LOADER_OFF,
} from './constants';

const initialState = {
  isResident: true,
  isAuthenticated: false,
  isAddingPermit: false,
  isLoader: false,
  language: LANGUAGES.EN,
  token: '',
  userDetails: {},
  errors: [],
  roles: {},
  permissions: {},
};

export default function Session(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    
    case LOGIN: { 
      const payload = action.payload;
      // newState.errors = [];
      // newState.token = action.payload.data.Token;
      if (payload.email === 'b@a.com' && payload.password === 'gosteep') {
        newState.isAuthenticated = true;
        newState.userDetails.name = 'Bruce Amberson';
      }
      else if (payload.email === 'k@a.com' && payload.password === 'gosteep') {
        newState.isAuthenticated = true;
        newState.userDetails.name = 'Bruce Amberson'; 
      }
      return newState;
    }

    case SET_USER_TYPE: {
      newState.isResident = action.payload;
      return newState;
    }

    case AUTHENTICATE_ACCOUNT: {
      newState.isAuthenticated = true;
      return newState;
    }
    
    case BROWSER_WARNING: {
      newState.showBrowserWarning = !state.showBrowserWarning;
      return newState;
    }

    case MOBILE_WARNING: {
      newState.showMobileWarning = !state.showMobileWarning;
      return newState;
    }

    case LANGUAGE_CHANGE: {
      newState.language = action.payload;
      return newState;
    }

    case TOGGLE_PERMIT_ADD: {
      newState.isAddingPermit = action.payload;
      return newState;
    }

    case TOGGLE_LOADER_ON: {
      newState.isLoader = true;
      return newState;
    }

    case TOGGLE_LOADER_OFF: {
      newState.isLoader = false;
      return newState;
    }

    default: 
      return newState;
  }
}