import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import CreditCardNumber from 'utils/components/CreditCardNumber';

export class CreditCardInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,
  };

  state = {
    inputValue: '',
    inputIndex: undefined,
    isError: undefined,
    helperMsg: '',

    regex: /^4[0-9]{12}(?:[0-9]{3})?$/, // defaults to VISA
    cardLength: 16, // defaults to VISA and MASTERCARD

    /*
    VISA validation rules

    1) The string should not contain any special characters, alphabets, or whitespaces.
    2) The string should start with 4.
    3) The number of characters must be equal to 13 or 16. The old visa cards have 13 characters and the new ones have 16 characters.
      a) If the number of characters is equal to 13, the last 12 digits must be a number between 0 to 9.
      b)If the number of characters is equal to 16, the last 15 digits must be a number between 0 to 9.
    */
    visaRegex: /^4[0-9]{12}(?:[0-9]{3})?$/,
  
    /*
    Mastercard validation rules
    
    1) The string should not contain any special characters, alphabets, or whitespaces.
    2) The number of characters must be equal to 16.
    3) The string should start with either a 2-digit number range (ranging from 51 to 55) or a 4-digit number range (ranging from 2221 to 2720).
      a) If the string starts with a 2-digit number range (ranging from 51 to 55), the next 14 digits must be a number between 0 to 9.
      b) If the string starts with a 4-digit number range (ranging from 2221 to 2720), the next 12 digits must be a number between 0 to 9.
    */
    mcRegex: /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/,
    
    /*
    AMEX validation rules
    1) The string should not contain any special characters, alphabets, or whitespaces.
    2) This number of characters must be equal to 15.
    3) The string should start with 34 or 37.
    4) The last 13 digits must be a number between 0 to 9.
    */
    amexRegex: /^3[47][0-9]{13}$/,
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const inputValue = this.context.inputList[this.state.inputIndex].inputValue;
    
    let regex = /^4[0-9]{12}(?:[0-9]{3})?$/; // defaults to VISA;
    let cardLength = 16;

    if (prevState.inputValue !== inputValue) {
      if (inputValue?.length > 0) {
        const firstDigit = inputValue.substr(0,1);
        if (firstDigit === '4') { 
          regex = this.state.visaRegex;
        }
        else if (firstDigit === '5') { 
          regex = this.state.mcRegex;
        }
        else {
          regex = this.state.amexRegex;
          cardLength = 15;
        }
        this.setState({ inputValue, cardLength, regex });
      }
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const {
      inputName, labelText, isDisabled, isReadOnly, inputFocus,
      inputFullWidth, autoComplete, className, style,
    } = this.props;

    const { inputValue, isError, helperMsg, cardLength } = this.state;

    let cardFormat = '#### #### #### ####';
    if (inputValue && inputValue.length > 0) {
      const firstDigit = inputValue.substring(0, 1);
      if (firstDigit !== '4' || firstDigit !== '5') { 
        cardFormat = '#### ###### #####';
      }
    }

    return (
      <CreditCardNumber
        name={inputName}
        value={inputValue}
        label={labelText}
        disabled={isDisabled}
        helperText={helperMsg || this.context.helperTextManage(inputName)}
        error={isError || this.context.helperErrorManage(inputName)}
        autoFocus={inputFocus || false}
        fullWidth={inputFullWidth || false}
        autoComplete={autoComplete || 'on'}
        style={style}
        className={className}
        onChange={({ value }) => this.inputChange({ value })} // BaseNumberInput only provides { value, formattedValue, floatValue } for onChange
        InputProps={{
          readOnly: isReadOnly,
        }}
        inputProps={{
          format: cardFormat,
          maxLength: cardLength,
        }}
        key={inputName}
        variant='outlined'
      />
    );
  }

  inputChange = e => {
    let inputValue = e.value || '';

    const { inputName } = this.props;
    const regex = this.state.regex;
    let helperMsg = '';
    let isError = undefined;

    if (inputValue.length === 0) {
      inputValue = undefined;
    }
    else if (!inputValue.match('^\\d+$')) {
      inputValue = this.state.inputValue;
      isError = undefined;
    }
    else if (inputValue.length > 0 && !regex.test(inputValue)) {
      if (inputValue?.length > 0) {
        const firstDigit = inputValue.substring(0,1);
        if (firstDigit === '4') { 
          helperMsg = 'Please enter valid Visa number';
        }
        else if (firstDigit === '5') { 
          helperMsg = 'Please enter valid Mastercard number';
        }
        else if (firstDigit === '3') { 
          helperMsg = 'Please enter valid Mastercard number';
        }
        else {
          helperMsg = 'Please enter a valid CC number';
        }
      } 
      isError = true;
    }
    else {
      helperMsg = '';
      isError = undefined;
    }
    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  };

}

export default CreditCardInput;