import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { 
  IconButton,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import TranslationHandler from 'utils/translations/translation_handler';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import VehicleChangeForm from 'view/private/body/guest/VehicleChange/VehicleChangeForm';
import DropdownInput from 'utils/SmartForm/components/DropdownInput';

import { Modal } from '@timber-rider/parkingtree-client-common';
import { togglePermitAdd } from 'view/actions';

import styles from 'view/private/body/guest/VehicleChange/index.module.css';

export class VehicleChange extends Component {

  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      VehicleChange: PropTypes.shape({
        btn_next: PropTypes.string,
        btn_goback: PropTypes.string,
        lbl_choose_vehicle: PropTypes.string,
        txt_or: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_ok: PropTypes.string,
        lbl_confirm_exit: PropTypes.string,
        txt_are_sure: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmExit: false,
    hasVehicle: true,
    isAddVehicle: false,
  };

  render() {
    const { text } = this.props;
    const { hasVehicle, isAddVehicle } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.closeBtn}>
          <IconButton onClick={() => {
            this.setState({ confirmExit: true });
          }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <SmartFormProvider>
          {hasVehicle &&
          <React.Fragment>
            <div className={styles.chooseVehicle}>
              <DropdownInput
                inputName='myVehicle'
                isRequired={true}
                labelText={text.VehicleChange.lbl_choose_vehicle}
                style={{ width: '90%', height: '75px' }}
                options={vehicles.map(VerticalShadesClosedRounded => ({
                  value: VerticalShadesClosedRounded.id,
                  display: VerticalShadesClosedRounded.name,
                }))}
              />
            </div>
            <div className={styles.chooseBtn}>
              <Button
                style={{ width: '100px' }}
                onClick={() => this.props.history.goBack()}
              >
                {text.VehicleChange.btn_goback}
              </Button> 
              <Button
                variant='contained'
                onClick={() => {
                  this.props.togglePermitAdd(false);
                  this.props.history.push('/guest');
                }}
              >
                {text.VehicleChange.btn_next}
              </Button>
            </div>
            <div className={styles.orAddVehicle}>
              {text.VehicleChange.txt_or}
            </div>
          </React.Fragment>}
          {isAddVehicle
            ?
            <div className={styles.form}>         
              <VehicleChangeForm />
            </div>
            :
            <div className={styles.form}>         
              <Button
                variant='contained'
                onClick={() => this.setState({ isAddVehicle: true, hasVehicle: false })}
              >
                add new vehicle
              </Button>
            </div>
          }
        </SmartFormProvider>
        {this.confirmExit()}
      </div>
    );
  }

  confirmExit() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.VehicleChange.btn_cancel,
        action: () => this.setState({ confirmExit: false }),
        buttonType: 'text',
      },
      {
        label: text.VehicleChange.btn_ok,
        action: () => {
          this.props.togglePermitAdd(false);
          this.props.history.push('/guest');
        },
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmExit: false })}
        show={this.state.confirmExit}
        title={text.VehicleChange.lbl_confirm_exit}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.VehicleChange.txt_are_sure}
        </div>
      </Modal>
    );
  }
}

export default connect(null, { togglePermitAdd })(TranslationHandler(VehicleChange));

const vehicles = [
  { id: 0, name: 'Tundra' },
  { id: 2, name: 'Rogue' },
];