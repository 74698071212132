import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import TranslationHandler from 'utils/translations/translation_handler';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import { togglePermitAdd } from 'view/actions';
import { Modal } from '@timber-rider/parkingtree-client-common';

import PaymentAddForm from 'view/private/body/guest/PaymentAdd/PaymentAddForm';

import styles from 'view/private/body/guest/PaymentAdd/index.module.css';

export class PaymentAdd extends Component {

  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      PaymentAdd: PropTypes.shape({
        txt_payment_details: PropTypes.string,
        txt_total: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_ok: PropTypes.string,
        lbl_confirm_exit: PropTypes.string,
        txt_are_sure: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmExit: false,
  };

  render() {
    const { text } = this.props;
    return (
      <React.Fragment>
        <div className={styles.closeBtn}>
          <IconButton onClick={() => {
            this.setState({ confirmExit: true });
          }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.pageTitle}>{text.PaymentAdd.txt_payment_details}</div>
        <div>
          <div className={styles.permit_container}>
            <div className={styles.permitTitle}>
              <div style={{ textAlign: 'center' }}>Assigned Covered Permit</div>
              <div style={{ textAlign: 'center' }}>Space #100</div>
            </div>
            <div className={styles.permitInfo}>
              <strong>Heber’s Truck- 2024 Tesla Cybertruck</strong><br /><br />
              Plate: WHA2EB (AZ)<br />
              VIN: 159872<br />
              Mountain View Apartments<br />

              <span>Renews Feb 11, 2024 at 1:29 PM</span>
            </div>
            <div className={styles.lblTotal}>{text.PaymentAdd.txt_total}</div>
            <div className={styles.totalAmt}>$25.00</div>
          </div>
          {this.confirmExit()}
        </div>
        <div>
          <SmartFormProvider>
            <PaymentAddForm />
          </SmartFormProvider>
        </div>
      </React.Fragment>
    );
  }

  confirmExit() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.PaymentAdd.btn_cancel,
        action: () => this.setState({ confirmExit: false }),
        buttonType: 'text',
      },
      {
        label: text.PaymentAdd.btn_ok,
        action: () => {
          this.props.togglePermitAdd(false);
          this.props.history.push('/guest');
        },
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmExit: false })}
        show={this.state.confirmExit}
        title={text.PaymentAdd.lbl_confirm_exit}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.PaymentAdd.txt_are_sure}
        </div>
      </Modal>
    );
  }
}

export default connect(null, { togglePermitAdd })(TranslationHandler(PaymentAdd));