import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button } from '@mui/material';
import { Modal } from '@timber-rider/parkingtree-client-common';

import TranslationHandler from 'utils/translations/translation_handler';
import DropdownInput from 'utils/SmartForm/components/DropdownInput';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import { updateUserType } from 'view/actions';

import styles from 'view/private/body/guest/SelectUnit/SelectUnitForm/index.module.css';

const units = [
  { id: 0, units: '101' },
  { id: 1, units: '102' },
  { id: 2, units: '103' },
  { id: 3, units: '104' },
  { id: 4, units: '105' },  
  { id: 5, units: '106' },
  { id: 6, units: '107' },
  { id: 7, units: '108' },
  { id: 8, units: '109' },
];

const select = state => ({
  isResident: state.session.isResident,
});

export class SelectUnitForm extends Component {
  
  static propTypes = {
    updateUserType: PropTypes.func.isRequired,
    isResident: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      SelectUnit: PropTypes.shape({
        btn_next: PropTypes.string,
        btn_goback: PropTypes.string,
        txt_title: PropTypes.string,
        lbl_unit: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_ok: PropTypes.string,
        lbl_confirm_exit: PropTypes.string,
        txt_are_sure: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmResident: false,
  };

  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.SelectUnit.txt_title}</div>
        <div className={styles.unitNumber}>
          <DropdownInput
            inputName='unit'
            isRequired={true}
            labelText={text.SelectUnit.lbl_unit}
            style={{ width: '100%', height: '75px' }}
            options={units.map(unit => ({
              value: unit.id,
              display: unit.units,
            }))}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            style={{ width: '100px' }}
            onClick={() => this.props.history.goBack()}
          >
            {text.SelectUnit.btn_goback}
          </Button>
          <Button
            variant='contained'
            style={{ width: '125px' }}
            onClick={() => {
              this.setState({ confirmResident: true });
            }}
          >
            {text.SelectUnit.btn_next}
          </Button>
        </div>
        {this.confirmResident()}
      </div>
    );
  }

  confirmResident() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.SelectUnit.btn_cancel,
        action: () => this.setState({ confirmResident: false }),
        buttonType: 'text',
      },
      {
        label: text.SelectUnit.btn_ok,
        action: () => {
          this.props.updateUserType(true);
          this.props.history.push('/resident/permit-select');
        },
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmResident: false })}
        show={this.state.confirmResident}
        title={text.SelectUnit.lbl_confirm_exit}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.SelectUnit.txt_are_sure}
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(select, { 
  updateUserType
})(TranslationHandler(SelectUnitForm)));
