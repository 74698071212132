import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { togglePermitAdd } from 'view/actions';

import { Button } from '@mui/material';

import TranslationHandler from 'utils/translations/translation_handler';

import LicensePlateInput from 'utils/SmartForm/components/LicensePlateInput';
import StateInput from 'utils/SmartForm/components/StateInput';
import VINInput from 'utils/SmartForm/components/VINInput';
import DropdownInput from 'utils/SmartForm/components/DropdownInput';

import styles from 'view/private/body/resident/VehicleChange/VehicleChangeForm/index.module.css';

const makes = [
  { id: 0, name: 'Toyota' },
  { id: 1, name: 'Honda' },
  { id: 2, name: 'GMC' },
];

const models = [
  { id: 0, name: 'Tundra' },
  { id: 1, name: 'Tacoma' },
  { id: 2, name: 'Prius' },
];

const years = [
  { id: 2024, name: '2024' },
  { id: 2023, name: '2023' },
  { id: 2022, name: '2022' },
];

const colors = [
  { id: 0, name: 'Silver' },
  { id: 1, name: 'Gray' },
  { id: 2, name: 'Black' },
];

export class VehicleChangeForm extends Component {
  
  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      VehicleChangeForm: PropTypes.shape({
        btn_next: PropTypes.string,
        btn_goback: PropTypes.string,
        lbl_color: PropTypes.string,
        lbl_license_plate: PropTypes.string,
        lbl_make: PropTypes.string,
        lbl_model: PropTypes.string,
        lbl_state: PropTypes.string,
        lbl_vin: PropTypes.string,
        lbl_year: PropTypes.string,
        txt_notice: PropTypes.string,
        txt_vehicle_info: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>{text.VehicleChangeForm.txt_vehicle_info}</div>
        <div className={styles.plate}>
          <LicensePlateInput
            inputName='plate'
            isRequired={true}
            labelText={text.VehicleChangeForm.lbl_license_plate}
            style={{ width: '100%', height: '75px' }}
            inputFocus={true}
          />
        </div>
        <div className={styles.state}>
          <StateInput
            inputName='state'
            isRequired={true}
            labelText={text.VehicleChangeForm.lbl_state}
            style={{ width: '100%', height: '75px' }}
          />
        </div>
        <div className={styles.lastSixVin}>
          <VINInput
            inputName='VIN'
            isRequired={true}
            labelText={text.VehicleChangeForm.lbl_vin}
            style={{ width: '100%', height: '75px' }}
          />
        </div>
        <div className={styles.make}>
          <DropdownInput
            inputName='make'
            isRequired={true}
            labelText={text.VehicleChangeForm.lbl_make}
            style={{ width: '100%', height: '75px' }}
            options={makes.map(make => ({
              value: make.id,
              display: make.name,
            }))}
          />
        </div>
        <div className={styles.model}>
          <DropdownInput
            inputName='model'
            isRequired={true}
            labelText={text.VehicleChangeForm.lbl_model}
            style={{ width: '100%', height: '75px' }}
            options={models.map(model => ({
              value: model.id,
              display: model.name,
            }))}
          />
        </div>
        <div className={styles.year}>
          <DropdownInput
            inputName='year'
            isRequired={true}
            labelText={text.VehicleChangeForm.lbl_year}
            style={{ width: '100%', height: '75px' }}
            options={years.map(year => ({
              value: year.id,
              display: year.name,
            }))}
          />
        </div>
        <div className={styles.colors}>
          <DropdownInput
            inputName='color'
            isRequired={true}
            labelText={text.VehicleChangeForm.lbl_color}
            style={{ width: '100%', height: '75px' }}
            options={colors.map(color => ({
              value: color.id,
              display: color.name,
            }))}
          />
        </div>
        <div className={styles.notice}>{text.VehicleChangeForm.txt_notice}</div>
        <div className={styles.buttons}>
          <Button
            style={{ width: '100px' }}
            onClick={() => this.props.history.goBack()}
          >
            {text.VehicleChangeForm.btn_goback}
          </Button>
          <Button
            variant='contained'
            style={{ width: '125px' }}
            onClick={() => {
              this.props.togglePermitAdd(false);
              this.props.history.push('/resident');
            }}
          >
            {text.VehicleChangeForm.btn_next}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, { togglePermitAdd })(TranslationHandler(VehicleChangeForm)));