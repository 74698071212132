import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import Header from 'view/public/Header';

import { Button } from '@mui/material';

import { coreColors } from 'utils/theme/parking_tree';

import styles from 'view/public/CreateAccount/AccountCreateConfirmation/index.module.css';

export class AccountCreationConfirmation extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      AccountCreateConfirmation: PropTypes.shape({
        btn_create_account: PropTypes.string,
        btn_goback: PropTypes.string,
        lbl_account_type: PropTypes.string,
        lbl_name: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_login: PropTypes.string,
        lbl_mobile: PropTypes.string,
        lbl_unit: PropTypes.string,
        txt_title: PropTypes.string,
        txt_haveacct: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    const { text } = this.props;
    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}><Header /></div>
          <div className={styles.pageTitle}>{text.AccountCreateConfirmation.txt_title}</div>
          <div className={styles.details}>
          
            <div className={styles.detailsContainer}>
              <div className={styles.acctTypeLbl}>{text.AccountCreateConfirmation.lbl_account_type}:</div>
              <div className={styles.acctType}>resident</div>
              <div className={styles.nameLbl}>{text.AccountCreateConfirmation.lbl_name}:</div>
              <div className={styles.name}>Bruce Amberson</div>
              <div className={styles.emailLbl}>{text.AccountCreateConfirmation.lbl_email}:</div>
              <div className={styles.email}>b@a.com</div>
              <div className={styles.mobileLbl}>{text.AccountCreateConfirmation.lbl_mobile}:</div>
              <div className={styles.mobile}>(435) 760-6416</div>
              <div className={styles.unitLbl}>{text.AccountCreateConfirmation.lbl_unit}:</div>
              <div className={styles.unit}>100</div>
            </div>

          </div>
          <div className={styles.btns}>
            <Button
              style={{ width: '100px' }}
              onClick={() => this.props.history.push('/account-details')}
            >
              {text.AccountCreateConfirmation.btn_goback}
            </Button>
            <Button
              variant='contained'
              style={{ width: '200px' }}
              onClick={() => this.props.history.push('/create-password')}
            >
              {text.AccountCreateConfirmation.btn_create_account}
            </Button>
          </div>
        </div>
        <div className={styles.haveAccount}>
          <p>
            {text.AccountCreateConfirmation.txt_haveacct}
            <Link to='/' style={{ color: coreColors.primary, paddingLeft: '5px' }}>
              {text.AccountCreateConfirmation.lbl_login}
            </Link>
          </p>
        </div>
      </>
    );
  }
}

export default withRouter(TranslationHandler(AccountCreationConfirmation));