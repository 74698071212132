import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import Header from 'view/public/Header';

import { Button } from '@mui/material';

import { updateAcctDetails } from 'view/public/CreateAccount/actions';

import NameInput from 'utils/SmartForm/components/NameInput';
import PhoneInput from 'utils/SmartForm/components/PhoneInput';
import DropdownInput from 'utils/SmartForm/components/DropdownInput';

import { coreColors } from 'utils/theme/parking_tree';

import styles from 'view/public/CreateAccount/AccountDetails/AccountDetailsForm/index.module.css';

const select = state => ({
  details: state.accountDetails.details,
  isResident: state.session.isResident,
});

export class AccountDetails extends Component {
  
  static propTypes = {
    updateAcctDetails: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    isResident: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      AccountDetails: PropTypes.shape({
        btn_add_details: PropTypes.string,
        btn_goback: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_first_name: PropTypes.string,
        lbl_last_name: PropTypes.string,
        lbl_login: PropTypes.string,
        lbl_mobile: PropTypes.string,
        lbl_select_unit: PropTypes.string,
        txt_create_account: PropTypes.string,
        txt_haveacct: PropTypes.string,
        txt_sms_disclaimer: PropTypes.string,
      }),
    }).isRequired,
  };
  
  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;
    const isResident = cloneDeep(this.props.isResident);
    
    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}><Header /></div>
          <div className={styles.pageTitle}>{text.AccountDetails.txt_create_account}</div>
          <div className={styles.fName}>
            <NameInput
              inputName='firstName'
              isRequired={true}
              labelText={text.AccountDetails.lbl_first_name}
              style={{ width: '150px', height: '75px' }}
              autoComplete={'off'}
              inputFocus={true}
            />
          </div>
          <div className={styles.lName}>
            <NameInput
              inputName='lastName'
              isRequired={true}
              labelText={text.AccountDetails.lbl_last_name}
              style={{ width: '150px', height: '75px' }}
              autoComplete={'off'}
            />
          </div>
          <div className={styles.mobile}>
            <PhoneInput
              inputName='mobile'
              isRequired={true}
              labelText={'mobile'}
              style={{ width: '300px', height: '75px' }}
              autoComplete={'off'}
            />
          </div>
          <div className={styles.mobileDisclaimer}>
            {text.AccountDetails.txt_sms_disclaimer}
          </div>

          {isResident
            ?
            <div className={styles.unit}>
              <DropdownInput
                inputName='unit'
                isRequired={true}
                labelText={text.AccountDetails.lbl_select_unit}
                style={{ width: '300px', textAlign: 'left' }}
                options={[
                  { value: '100', display: '100' },
                  { value: '101', display: '101' }
                ]}
              />
            </div>
            :
            ''
          }

          <div className={styles.btns}>
            <Button
              style={{ width: '100px' }}
              onClick={() => this.props.history.push('/user-type')}
            >
              {text.AccountDetails.btn_goback}
            </Button>
            <Button
              variant='contained'
              style={{ width: '200px' }}
              onClick={e => this.createAccount(e)}
            >
              {text.AccountDetails.btn_add_details}
            </Button>
          </div>
        </div>
        <div className={styles.haveAccount}>
          <p>
            {text.AccountDetails.txt_haveacct}
            <Link to='/' style={{ color: coreColors.primary, paddingLeft: '5px' }}>
              {text.AccountDetails.lbl_login}
            </Link>
          </p>
        </div>
      </>
    );
  }

  createAccount(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedList = this.context.prepInputList(validObj.inputList);
      const details = cloneDeep(this.props.details);
      details.userDetails = formattedList;
      this.props.updateAcctDetails(details);
      const isResident = cloneDeep(this.props.isResident);
      if (isResident == true) {
        this.props.history.push('/resident/validate-email');
      } 
      else {
        this.props.history.push('/guest/validate-email');
      }
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }
}

export default withRouter(connect(select, {
  updateAcctDetails
})(TranslationHandler(AccountDetails)));