export const INITIALIZE_STORE = 'view/INITIALIZE_STORE';

export const SET_USER_TYPE = 'view/SET_USER_TYPE';

export const BROWSER_WARNING = 'view/BROWSER_WARNING';
export const MOBILE_WARNING = 'view/MOBILE_WARNING';
export const TOKEN_VALIDATE = 'view/TOKEN_VALIDATE';
export const LANGUAGE_CHANGE = 'view/LANGUAGE_CHANGE';
export const LANGUAGES = {
  EN: 'en',
  ES: 'es'
};

export const TOGGLE_LOADER_ON = 'view/TOGGLE_LOADER_ON';
export const TOGGLE_LOADER_OFF = 'view/TOGGLE_LOADER_OFF';

export const TOGGLE_PERMIT_ADD = 'view/TOGGLE_PERMIT_ADD';

export const LOGIN = 'view/LOGIN';
export const AUTHENTICATE_ACCOUNT = 'view/AUTHENTICATE_ACCOUNT';

export const USER_REGISTRATION = 'view/USER_REGISTRATION';
export const USER_REG_INFO_SYNC = 'view/USER_REG_INFO_SYNC';
export const RESEND_REGISTRATION_EMAIL = 'view/RESEND_REGISTRATION_EMAIL';
export const NEW_ACCOUNT_CREATED = 'view/NEW_ACCOUNT_CREATED';


export const PASSWORD_REQUIREMENTS = [
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^.{8,1000}$',
    PasswordRequirementId: 3,
    RequirementDescription: 'Must be at least eight characters long.',
    SecurityLevel: 2,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*\\d).{1,1000}$',
    PasswordRequirementId: 4,
    RequirementDescription: 'Must contain a numeric character.',
    SecurityLevel: 3,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*[^0-9a-zA-Z]).{1,1000}$',
    PasswordRequirementId: 5,
    RequirementDescription: 'Must contain a special character.',
    SecurityLevel: 4,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*[A-Z]).{1,1000}$',
    PasswordRequirementId: 6,
    RequirementDescription: 'Must contain an uppercase letter.',
    SecurityLevel: 1,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*[a-z]).{1,1000}$',
    PasswordRequirementId: 7,
    RequirementDescription: 'Must contain a lowercase letter.',
    SecurityLevel: 1,
    SecurityPoints: 1,
  }
];

export const PAYMENT_TYPES = {
  CARD: 'CARD',
  ACH: 'ACH',
};

export const CC_TYPES_LIST = [
  { id: 'VISA', name: 'Visa' },
  { id: 'MASTERCARD', name: 'Mastercard' },
  { id: 'AMEX', name: 'AMEX' },
];

export const CC_TYPES = {
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  AMEX: 'AMEX'
};

let currentYear = new Date()
  .getFullYear()
  .toString()
  .substr(-4);
currentYear = Number(currentYear);
export const EXPIRATION_YEARS = [
  { id: currentYear, name: currentYear },
  { id: currentYear + 1, name: currentYear + 1 },
  { id: currentYear + 2, name: currentYear + 2 },
  { id: currentYear + 3, name: currentYear + 3 },
  { id: currentYear + 4, name: currentYear + 4 },
  { id: currentYear + 5, name: currentYear + 5 },
  { id: currentYear + 6, name: currentYear + 6 },
];