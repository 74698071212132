import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import ValidateEmailForm from 'view/public/CreateAccount/ValidateEmail/ValidateEmailForm';

export class ValidateEmail extends Component {

  render() {
    return (
      <SmartFormProvider>
        <ValidateEmailForm />
      </SmartFormProvider>
    );
  }

}

export default ValidateEmail;