import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TranslationHandler from 'utils/translations/translation_handler';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { togglePermitAdd } from 'view/actions';

import styles from 'view/private/body/resident/Notification/index.module.css';

export class Notification extends Component {
  
  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Notification: PropTypes.shape({
        txt_title: PropTypes.string,
        lbl_permit_id: PropTypes.string,
        lbl_permit_space: PropTypes.string,
        lbl_apartment: PropTypes.string,
        lbl_unit: PropTypes.string,
        lbl_make: PropTypes.string,
        lbl_Model: PropTypes.string,
        lbl_plate: PropTypes.string,
        lbl_vin: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmDelete: false,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{text.Notification.txt_title}</div>
        <div className={styles.notification}>
          <div>
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                >
                  <div>
                    <div><strong>Permit Payment Failure!</strong> NAME for your</div>
                    <div>VEHICLE will <strong>auto-cancel on DATE</strong></div>
                    <div>unless you update your payment method.</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Divider />
                    <div><strong>{text.Notification.lbl_permit_id}</strong> <strong>MP-44567</strong></div>
                    <div>{text.Notification.lbl_permit_space}100</div>
                    <div>{text.Notification.lbl_apartment}</div>
                    <div>{text.Notification.lbl_unit} 200</div>
                    <div>{text.Notification.lbl_make}</div>
                    <div>{text.Notification.lbl_Model}</div>
                    <div>{text.Notification.lbl_plate}</div>
                    <div>{text.Notification.lbl_vin}</div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { togglePermitAdd })(TranslationHandler(Notification));