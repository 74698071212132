import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { TextField } from '@mui/material';

export class EmailInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,
  };

  state = {
    inputValue: '',
    isError: undefined,
    helperMsg: ''
  };

  static contextType = SmartFormContext;
  
  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const inputValue = this.context.inputList[this.state.inputIndex].inputValue;
    if (prevState.inputValue !== inputValue) {
      this.setState({ inputValue });
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const { 
      inputName, labelText, isDisabled, isReadOnly, inputFocus,
      inputFullWidth, autoComplete, className, style
    } = this.props;

    const { inputValue, isError, helperMsg } = this.state;
    
    return (
      <TextField
        name={inputName}
        value={inputValue}
        label={labelText}
        disabled={isDisabled}
        helperText={helperMsg || this.context.helperTextManage(inputName)}
        error={isError || this.context.helperErrorManage(inputName)}
        autoFocus={inputFocus || false}
        fullWidth={inputFullWidth || false}
        autoComplete={autoComplete || 'on'}
        style={style}
        className={className}
        variant='outlined'
        onChange={e => this.emailValidator(e)}
        inputProps={{
          maxLength: 128
        }}
        InputProps={{
          readOnly: isReadOnly,
        }}
        key={inputName}
      />
    );
  }

  emailValidator = e => {
    let inputValue = e.target.value.trimStart() || '';
    const { inputName } = this.props;

    /*
    Email validation rules - must match backend validation
    
    All alphanumeric characters allowed

    character: _
      1) can only appear on the left side of the @ sign
      
    character: +
      1)  can only appear on left side of @ sign
      2)  can have multiple
      3)  cannot be consecutive
      4)  cannot appear immediately before @
      
    character: -
      1)  can appear on right or left side of @
      2)  can have multiple
      3)  cannot be consecutive
      4)  cannot appear immediately before or after @

    character: .
      1)  can appear on right or left side of @
      2)  can have multiple
      3)  cannot be consecutive
      4)  cannot appear immediately before or after @
      5)  at least one must appear on right side of @
      
    character: '
      1)  can only appear on left side of @ sign
      2)  can have multiple
      3)  cannot be consecutive
      4)  cannot appear immediately before @

    character: @
      1) only one is allowed.
      2) there must be valid text to the right and left of @
    */

    const emailRegex = /^\w+([-+_.']\w+)*@[A-Za-z0-9]+([-.][A-Za-z0-9]+)*\.[A-Za-z0-9]+([-.][A-Za-z0-9]+)*$/;
    
    let helperMsg = '';
    let isError = undefined;
    
    if (inputValue.length === 0 && !emailRegex.test(inputValue)) {
      inputValue = '';
    }
    else if (inputValue.length > 0 && !emailRegex.test(inputValue)) {
      helperMsg = 'Please provide a valid Email Address';
      isError = true;
    }
    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  };
}

export default EmailInput;