import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TranslationHandler from 'utils/translations/translation_handler';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Modal } from '@timber-rider/parkingtree-client-common';
import { togglePermitAdd } from 'view/actions';

import styles from 'view/private/body/resident/Home/index.module.css';

export class ResidentHome extends Component {
  
  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      ResidentHome: PropTypes.shape({
        btn_add_permit: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_cancel_permit: PropTypes.string,
        btn_edit_vehicle: PropTypes.string,
        btn_ok: PropTypes.string,
        btn_permit_vehicle: PropTypes.string,
        btn_remove_vehicle: PropTypes.string,
        lbl_confirm_delete: PropTypes.string,
        lbl_expiring_soon: PropTypes.string,
        lbl_expiration_date: PropTypes.string,
        lbl_license_plate: PropTypes.string,
        lbl_next_payment: PropTypes.string,
        lbl_permit_id: PropTypes.string,
        lbl_permit_type: PropTypes.string,
        lbl_property: PropTypes.string,
        lbl_start_date: PropTypes.string,
        lbl_unit: PropTypes.string,
        lbl_vin: PropTypes.string,
        txt_active: PropTypes.string,
        txt_are_sure: PropTypes.string,
        txt_not_permitted: PropTypes.string,
        txt_permitted_vehicles: PropTypes.string,
        txt_unpermitted_vehicles: PropTypes.string,
        txt_delete_vehicle: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmDelete: false,
    confirmDeleteVehicle: false,
  };
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.newPermit}>
          <Button
            variant='contained'
            onClick={() => {
              this.props.togglePermitAdd(true);
              this.props.history.push('/resident/permit-select');
            }}
          >
            {text.ResidentHome.btn_add_permit}
          </Button>
        </div>
        <div className={styles.permittedVehLbl}>{text.ResidentHome. txt_permitted_vehicles}</div>
        <div className={styles.permittedVeh}>

          <div>
            <div className={styles.activePanelStyle}>{text.ResidentHome.txt_active}</div>
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                >                  
                  <div>
                    <div style={{ fontWeight: 'bold' }}>2023 Ford F-150 - $25.00</div>
                    <div style={{ display: 'flex', marginTop: '3px' }}>
                      <div>Assigned Covered</div>
                      <div style={{ color: '#EC7063', fontWeight: 'bold', textIndent: '5px' }} />
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div><strong>{text.ResidentHome.lbl_license_plate}</strong> CEA82F (AZ)</div>
                    <div><strong>{text.ResidentHome.lbl_property}</strong> River Heights</div>
                    <div><strong>{text.ResidentHome.lbl_unit}</strong> 100</div>
                    <div><strong>{text.ResidentHome.lbl_vin}</strong> 246976</div>
                    <div style={{ paddingBottom: '5px' }}><strong>{text.ResidentHome.lbl_permit_id}</strong> 13349</div>
                    <Divider />
                    <div style={{ paddingTop: '5px' }}><strong>{text.ResidentHome.lbl_start_date}</strong> 12/31/2023 1:39 PM MST</div>
                    <div><strong>{text.ResidentHome.lbl_next_payment}</strong> 01/31/2024 1:39 PM MST</div>
                    <div><strong>{text.ResidentHome.lbl_expiration_date}</strong> 01/31/2024 1:39 PM MST</div>
                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          this.props.togglePermitAdd(true);
                          this.props.history.push('/resident/vehicle-edit/0');
                        }}
                        style={{ marginRight: '10px' }}
                      >
                        {text.ResidentHome.btn_edit_vehicle}
                      </Button>
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => this.setState({ confirmDelete: true })}
                      >
                        {text.ResidentHome.btn_cancel_permit}
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                >
                  <div>
                    <div style={{ fontWeight: 'bold' }}>2022 Tesla Y - $15.00</div>
                    <div style={{ display: 'flex', marginTop: '3px' }}>
                      <div>Open Lot</div>
                      <div style={{ color: '#EC7063', fontWeight: 'bold', textIndent: '5px' }}>{text.ResidentHome.lbl_expiring_soon}</div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div><strong>{text.ResidentHome.lbl_license_plate}</strong> ZRP56T (AZ)</div>
                    <div><strong>{text.ResidentHome.lbl_property}</strong> River Heights</div>
                    <div><strong>{text.ResidentHome.lbl_unit}</strong> 100</div>
                    <div><strong>{text.ResidentHome.lbl_vin}</strong> 335211</div>
                    <div style={{ paddingBottom: '5px' }}><strong>{text.ResidentHome.lbl_permit_id}</strong> 13173</div>
                    <Divider />
                    <div style={{ paddingTop: '5px' }}><strong>{text.ResidentHome.lbl_start_date}</strong> 12/31/2023 1:39 PM MST</div>
                    <div><strong>{text.ResidentHome.lbl_next_payment}</strong> 01/31/2024 1:39 PM MST</div>
                    <div><strong>{text.ResidentHome.lbl_expiration_date}</strong> 01/31/2024 1:39 PM MST</div>
                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          this.props.togglePermitAdd(true);
                          this.props.history.push('/resident/vehicle-edit/0');
                        }}
                        style={{ marginRight: '10px' }}
                      >
                        {text.ResidentHome.btn_edit_vehicle}
                      </Button>
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => this.setState({ confirmDelete: true })}
                      >
                        {text.ResidentHome.btn_cancel_permit}
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

        </div>
        <div className={styles.unpermittedVehLbl}>{text.ResidentHome.txt_unpermitted_vehicles}</div>
        <div className={styles.unpermittedVeh}>

          <div>
            <div className={styles.inActivePanelStyle}>{text.ResidentHome.txt_not_permitted}</div>
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                >
                  2016 Nissan Rogue
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div><strong>{text.ResidentHome.lbl_license_plate}</strong> BRE35A (AZ)</div>
                    <div><strong>{text.ResidentHome.lbl_vin}</strong> 246976</div>
                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                      <Button
                        variant='contained'
                        onClick={() => {
                          this.props.togglePermitAdd(true);
                          this.props.history.push('/resident/permit-vehicle/0');
                        }}
                        style={{ width: '200px', marginBottom: '10px' }}
                      >
                        {text.ResidentHome.btn_permit_vehicle}
                      </Button><br />
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => this.setState({ confirmDeleteVehicle: true })}
                        style={{ width: '200px' }}
                      >
                        {text.ResidentHome.btn_remove_vehicle}
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

        </div>
        {this.confirmDelete()}
        {this.confirmDeleteVehicle()}
      </div>
    );
  }

  confirmDelete() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.ResidentHome.btn_cancel,
        action: () => this.setState({ confirmDelete: false }),
        buttonType: 'text',
      },
      {
        label: text.ResidentHome.btn_ok,
        action: () => null,
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDelete: false })}
        show={this.state.confirmDelete}
        title={text.ResidentHome.lbl_confirm_delete}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.ResidentHome.txt_are_sure}
        </div>
      </Modal>
    );
  }

  confirmDeleteVehicle() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.ResidentHome.btn_cancel,
        action: () => this.setState({ confirmDeleteVehicle: false }),
        buttonType: 'text',
      },
      {
        label: text.ResidentHome.btn_ok,
        action: () => null,
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDeleteVehicle: false })}
        show={this.state.confirmDeleteVehicle}
        title={text.ResidentHome.lbl_confirm_delete}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.ResidentHome.txt_delete_vehicle}
        </div>
      </Modal>
    );
  }
}

export default connect(null, { togglePermitAdd })(TranslationHandler(ResidentHome));