import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import ChangePasswordForm from 'view/private/body/guest/ChangePassword/ChangePasswordForm';

export class ChangePassword extends Component {

  render() {
    return (
      <SmartFormProvider>
        <ChangePasswordForm />
      </SmartFormProvider>
    );
  }

}

export default ChangePassword;