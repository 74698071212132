import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import Header from 'view/public/Header';

import { Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

import TextInput from 'utils/SmartForm/components/TextInput';
import { coreColors } from 'utils/theme/parking_tree';

import { updateAcctDetails } from 'view/public/CreateAccount/actions';
import { addAlert } from 'utils/components/alerts/actions';
import { ALERT_TYPES } from 'utils/components/alerts/constants';

import styles from 'view/public/CreateAccount/SearchProperty/SearchPropertyForm/index.module.css';

export class SearchPropertyForm extends Component {

  static propTypes = {
    updateAcctDetails: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    text: PropTypes.shape({
      SearchProperty: PropTypes.shape({
        btn_search: PropTypes.string,
        lbl_login: PropTypes.string,
        lbl_search: PropTypes.string,
        txt_find: PropTypes.string,
        txt_haveacct: PropTypes.string,
        txt_search: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    showProperties: false,
  };

  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;
    return (
      <div className={styles.formContainer}>
        <LoadingOverlay
          show={false}
          width='100%'
        > 
          <form onSubmit={this.searchProperty}>
            <div className={styles.Container}>
              <div className={styles.logo}>
                <Header />
              </div>
              <div className={styles.title}><p />{text.SearchProperty.txt_find}</div>
              <div className={styles.searchText}>
                <p>{text.SearchProperty.txt_search}</p>
              </div>
              <div className={styles.search}>
                <TextInput
                  inputName='searchProperty'
                  isRequired={true}
                  labelText={text.SearchProperty.lbl_search}
                  style={{ width: '300px', height: '75px' }}
                  autoComplete={'off'}
                  inputFocus={true}
                />
              </div>
              <div className={styles.btns}>
                <Button
                  type='submit'
                  variant='contained'
                  style={{ width: '200px' }}
                  onClick={e => this.searchProperty(e)}
                >
                  {text.SearchProperty.btn_search}
                </Button>
              </div>
              <div className={styles.propertyList}>
                {this.state.showProperties &&
                  <div style={{ textAlign: 'left' }}>
                    <Button 
                      variant='outlined'
                      onClick={() => this.addProperty()}
                      style={{ marginBottom: '10px' }}
                    >
                      River Heights
                      <NavigateNextIcon />
                    </Button><br />
                    <Button
                      variant='outlined'
                      onClick={() => null}
                      style={{ marginBottom: '10px' }}
                    >
                      River View Apts
                      <NavigateNextIcon />
                    </Button><br />
                    <Button
                      variant='outlined'
                      onClick={() => null}
                    >
                      Riverton Place
                      <NavigateNextIcon />
                    </Button><br />
                  </div>
                }
              </div>
            </div>
          </form>     
        </LoadingOverlay>
        <div className={styles.haveAccount}>
          <p>
            {text.SearchProperty.txt_haveacct}
            <Link to='/' style={{ color: coreColors.primary, paddingLeft: '5px' }}>
              {text.SearchProperty.lbl_login}
            </Link>
          </p>
        </div>
      </div>
    );
  }

  searchProperty(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedList = this.context.prepInputList(validObj.inputList);
      formattedList.searchProperty !== 'River'
        ? this.props.addAlert(ALERT_TYPES.SUCCESS, 'We didn\t find any properties that match your search.')
        : this.setState({ showProperties: true });

      // this.props.history.push('/forgot-password');
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }

  addProperty() {
    const propertyId = 1;
    this.props.updateAcctDetails({ propertyId });
    this.props.history.push('/user-type');
  }

}

export default withRouter(connect(null, { 
  updateAcctDetails, addAlert,
})(TranslationHandler(SearchPropertyForm)));