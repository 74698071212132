import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { 
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import TranslationHandler from 'utils/translations/translation_handler';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import SelectUnitForm from 'view/private/body/guest/SelectUnit/SelectUnitForm/index';

import { Modal } from '@timber-rider/parkingtree-client-common';
import { togglePermitAdd } from 'view/actions';

import styles from 'view/private/body/guest/SelectUnit/index.module.css';

export class SelectUnit extends Component {

  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    text: PropTypes.shape({
      VehicleAdd: PropTypes.shape({
        btn_next: PropTypes.string,
        lbl_choose_vehicle: PropTypes.string,
        txt_or: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_ok: PropTypes.string,
        lbl_confirm_exit: PropTypes.string,
        txt_are_sure: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    confirmExit: false,
    hasVehicle: true,
    isAddVehicle: false,
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.closeBtn}>
          <IconButton onClick={() => {
            this.setState({ confirmExit: true });
          }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <SmartFormProvider>
          <div className={styles.form}>         
            <SelectUnitForm />
          </div>
        </SmartFormProvider>
        {this.confirmExit()}
      </div>
    );
  }

  confirmExit() {
    const { text } = this.props;
    const actionButtons = [
      {
        label: text.VehicleAdd.btn_cancel,
        action: () => this.setState({ confirmExit: false }),
        buttonType: 'text',
      },
      {
        label: text.VehicleAdd.btn_ok,
        action: () => {
          this.props.togglePermitAdd(false);
          this.props.history.push('/guest');
        },
        buttonType: 'contained',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'sm'}
        modal={false}
        onCloseModal={() => this.setState({ confirmExit: false })}
        show={this.state.confirmExit}
        title={text.VehicleAdd.lbl_confirm_exit}
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {text.VehicleAdd.txt_are_sure}
        </div>
      </Modal>
    );
  }
}

export default connect(null, { togglePermitAdd })(TranslationHandler(SelectUnit));
