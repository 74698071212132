import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import NameInput from 'utils/SmartForm/components/NameInput';
import EmailInput from 'utils/SmartForm/components/EmailInput';
import PhoneInput from 'utils/SmartForm/components/PhoneInput';
import TextInput from 'utils/SmartForm/components/TextInput';

import styles from 'view/public/ContactInfo/ContactInfoForm/index.module.css';

export class ContactInfoForm extends Component {
  
  static propTypes = {
    text: PropTypes.shape({
      ContactInfoForm: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_send: PropTypes.string,
        lbl_name: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_phone: PropTypes.string,
        lbl_property: PropTypes.string,
        lbl_message: PropTypes.string,
        txt_get_in_contact: PropTypes.string,
      }),
    }).isRequired,
  };

  static contextType = SmartFormContext;
  
  render() {
    const { text } = this.props;
    return (
      <div className={styles.formContainer}>
        <div className={styles.pageTitle}>
          {text.ContactInfoForm.txt_get_in_contact}
        </div>
        <div className={styles.nameInput}>
          <NameInput
            inputName='name'
            isRequired={true}
            labelText={text.ContactInfoForm.lbl_name}
            style={{ width: '300px', height: '75px' }}
            inputFocus={true}
          />
        </div>
        <div className={styles.emailInput}>
          <EmailInput
            inputName='email'
            isRequired={true}
            labelText={text.ContactInfoForm.lbl_email}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.phoneInput}>
          <PhoneInput
            inputName='phone'
            isRequired={true}
            labelText={text.ContactInfoForm.lbl_phone}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.propertyInput}>
          <TextInput
            inputName='property'
            isRequired={true}
            labelText={text.ContactInfoForm.lbl_property}
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.message}>
          <TextInput
            inputName='message'
            isRequired={true}
            labelText='Message'
            style={{ width: '300px', height: '75px' }}
          />
        </div>
        <div className={styles.btn}>
          <Button
            type='submit'
            onClick={() => this.props.history.push('/')}
            style={{ marginRight: '10px', width: '100px' }}
          >
            {text.ContactInfoForm.btn_cancel}
          </Button>
          <Button
            variant='contained'
            onClick={e => this.sendContactForm(e)}
            style={{ width: '100px' }}
          >
            {text.ContactInfoForm.btn_send}
          </Button>
        </div>
      </div>
    );
  }

  sendContactForm(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedList = this.context.prepInputList(validObj.inputList);
      alert(JSON.stringify(formattedList));
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }
}

export default withRouter(TranslationHandler(ContactInfoForm));